import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Image,

  Stack,
  Text,
} from '@chakra-ui/react'
import { FacebookIcon, WhatsAppIcon } from '../../assets/icons/SocialIcons'
export const CardWithForm = ({ title = '', children, image,buttons ,...props}) => (
  <Box
    py={{
      base: '4',
      md: '8',
    }}

  >
    <Container maxW="xl" >
      <Box
        bg="bg.surface"
        shadow={'2xl'}
        borderRadius="lg"

        p={{
          base: '4',
          md: '6',
        }}
        {...props}
      >
        <Stack spacing="5">
          <Text textStyle="lg" fontWeight="medium">
            {title}
          </Text>
          <Center>
            <Image src={image} maxH={['13rem', '18rem']} maxW={['13rem', '20rem']} alt="Card Image" />
          </Center>
          <Stack spacing="1">
            {children}
          </Stack>
          <Stack
            spacing="3"
            direction={{
              base: 'column',
              sm: 'row',
            }}
          >
            {buttons}
          </Stack>

        </Stack>
      </Box>
    </Container>
  </Box>
)
