'use client'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    useColorModeValue,
    Text,
    Container,
    Heading,
    AccordionIcon,
    Stack,
  
    Box,
    Link,
} from '@chakra-ui/react'
import { Trans } from 'react-i18next'
import { CR_FAQ_CAN_I_DO_IT_ANSWER, CR_FAQ_CAN_I_DO_IT_QUESTION, CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER, CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION, CR_FAQ_DESCRIPTION, CR_FAQ_HOW_LONG_TAKE_ANSWER, CR_FAQ_HOW_LONG_TAKE_QUESTION, CR_FAQ_HOW_WORK_ANSWER, CR_FAQ_HOW_WORK_QUESTION, CR_FAQ_SUBTITLE, CR_FAQ_TITLE, CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER, CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION, CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER, CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION, CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER, CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION } from 'src/locales/types'


const transPath = "page.credit_repair.faqs"
const faqs = [
    {
        question: CR_FAQ_HOW_WORK_QUESTION,
        answer: CR_FAQ_HOW_WORK_ANSWER
    },
    {
        question: CR_FAQ_HOW_LONG_TAKE_QUESTION,
        answer: CR_FAQ_HOW_LONG_TAKE_ANSWER
    },
    {
        question: CR_FAQ_CAN_I_DO_IT_QUESTION,
        answer: CR_FAQ_CAN_I_DO_IT_ANSWER,
    },
    {
        question: CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_QUESTION,
        answer: CR_FAQ_CAN_LINARES_GUARANTEE_RESULTS_ANSWER

    }, {
        question: CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_QUESTION,
        answer: CR_FAQ_WHY_CREDIT_ITS_IMPORTANT_ANSWER
    },
    {
        question: CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_QUESTION,
        answer: CR_FAQ_WILL_DISPUTING_ERRORS_HURT_CREDIT_SCORE_ANSWER
    }, {
        question: CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_QUESTION,
        answer: CR_FAQ_WHAT_IS_GOOD_CREDIT_SCORE_ANSWER
    }
]
export const CreditRepairFAQ = () => {
    return (<Box bg={useColorModeValue('gray.50', 'gray.800')} as='section' className='FAQ-container'>
        <Container maxW={{ base: "full", lg: "4xl", xl: "7xl" }} >
            <Stack
                textAlign={'center'}
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}>
                <Heading
                    fontWeight={600}
                    fontSize={{ base: 'xl', sm: '3xl', lg: '6xl' }}
                    lineHeight={'110%'}>
                    <Trans i18nKey={`${transPath}.${CR_FAQ_TITLE}`} />{' '}
                    <Text ml={5} as={'span'} color={'green.400'}>
                        <Trans i18nKey={`${transPath}.${CR_FAQ_SUBTITLE}`} />
                    </Text>
                </Heading>
                <Text color={'gray.500'} fontSize={'lg'} fontWeight={'bold'} maxW={{ base: "full", lg: "4xl", 'xl': "6xl" }}>
                    <Trans i18nKey={`${transPath}.${CR_FAQ_DESCRIPTION}`} />
                </Text>


                <Container p={5} >
                    <Accordion allowMultiple width="100%" maxW={{ base: "full", lg: "4xl", 'xl': "6xl" }} rounded="lg">

                        {faqs.map(faq => <AccordionItem key={faq.question} p={2} my={5}>
                            <AccordionButton
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"

                            >
                                <Heading textAlign={'start'} as={'h4'} size={'xl'} fontSize={{ base: "md", md: "xl", lg: "2xl" }} lineHeight={'8'}>
                                    <Trans i18nKey={`${transPath}.${faq.question}`} />
                                </Heading>
                                <AccordionIcon fontSize={'3xl'} />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text color="gray.600" fontSize={'lg'} fontWeight={'bold'} textAlign={'start'}>
                                    <Trans i18nKey={`${transPath}.${faq.answer}`} />
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>)}
                    </Accordion>
                </Container>
                <Stack spacing={6} direction={'row'}>

                    <Box size='xs' rounded={'2xl'} bg={'bg.muted'} p={8}>
                        <Link size={'xs'} href='tel:+17869726285' rounded={'full'} px={6} fontSize={'xl'}>
                            Contact Us
                        </Link>
                    </Box>
                </Stack>
            </Stack>
        </Container>
    </Box>
    )
}