


const esDictionary = {
    month: "mes",

}


export default esDictionary;

