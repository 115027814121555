import { NAVBAR_LINK_ABOUT_US, NAVBAR_LINK_CONTACT_US, NAVBAR_LINK_CREDIT_REPAIR, NAVBAR_LINK_HOME, NAVBAR_LINK_IMMIGRATION, NAVBAR_LINK_LOGIN, NAVBAR_LINK_PASSPORT, NAVBAR_LINK_SERVICES, NAVBAR_LINK_SIGNUP, NAVBAR_LINK_SUPPORT, NAVBAR_LINK_TAXES, NAVBAR_LINK_TRAVEL } from "../../../locales/types"
import AccountingFF from "../../coroinside-icon/AccountingFF"
import CreditScoreFF from "../../coroinside-icon/CreditScoreFF"
import ImmigrationFF from "../../coroinside-icon/ImmigrationFF"
import PassportFF from "../../coroinside-icon/PassportFF"
import RelaxFF from "../../coroinside-icon/RelaxFF"



export const navbarButtons = [

    {
        title: 'Home',
        trans: NAVBAR_LINK_HOME,
        href: '/',

    },

]

export const navbarRightLinks = [
   
    {
        title: 'Contact',
        trans: NAVBAR_LINK_CONTACT_US,
        href: '/',

    },
    {
        title: 'Support',
        trans: NAVBAR_LINK_SUPPORT,
        href: '/support',

    },

    {
        title: 'About',
        trans: NAVBAR_LINK_ABOUT_US,
        href: '/about',

    },

]


export const services = [
    {
        title: 'Credit_Repair',
        trans: NAVBAR_LINK_CREDIT_REPAIR,
        href: '/credit_repair',
        icon: CreditScoreFF,
    },
    {
        title: 'Immigration',
        trans: NAVBAR_LINK_IMMIGRATION,
        href: '/immigration',
        icon: ImmigrationFF

    },
    {
        title: 'Taxes',
        trans: NAVBAR_LINK_TAXES,
        href: '/taxes',
        icon: AccountingFF

    },

    {
        title: 'Passport',
        trans: NAVBAR_LINK_PASSPORT,
        href: '/passport',
        icon: PassportFF

    },
    {
        title: 'Travle',
        trans: NAVBAR_LINK_TRAVEL,
        href: '/travel',
        icon: RelaxFF


    },
]



export const guestLinks = [
    {
        title: 'Log in',
        trans: NAVBAR_LINK_LOGIN,
        href: '/login',


    },
    {
        title: 'Signup',
        trans: NAVBAR_LINK_SIGNUP,
        href: '/registration',

    },
]

export const authenticatedLinks = [

]
