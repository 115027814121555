import { Link } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { Trans } from "react-i18next";

export const NavbarButtonLink = ({ item, afterAction, ...props }) => {
    const navigate = useNavigate()
    return (<Link color={'white'} key={item?.title} onClick={() => {
        navigate(item?.href);
        !!afterAction && afterAction();
    }} size="lg"  {...props}{...item?.props}>
        <Trans i18nKey={`component.navbar.${item?.trans}`} />
    </Link>)
}