import { useColorMode } from "@chakra-ui/react"
import SVGFactory from "./SVGFactory"


const PassportFF = ({
    size = 52, // or any default size of your choice
    color,
    presetColor = true // or any color of your choice

}) => {
    const { colorMode } = useColorMode()
    const adaptativeColor = color ? color : colorMode === 'dark' ? 'white' : 'black'
    const fil0 = { fill: presetColor ? '#FFA500' : adaptativeColor, fillRule: 'nonzero' }
    const fil1 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    return (
        <SVGFactory >
            <g >
                <path  {...fil0} d="M1582.21 408.99l0 -284.91c0,-43.48 -41.65,-75.44 -84.23,-63.9l-1251.21 344.39c-6.19,1.76 -12.4,4.42 -18.59,7.98 11.48,-2.66 23.87,-3.56 36.28,-3.56l1317.76 0z" />
                <path  {...fil1} d="M789.64 1449.85c0,-28.43 1.02,-57.03 2.69,-85.55l-200.16 0c-5.54,27.69 -8.69,56.26 -8.69,85.55 0,29.29 3.15,57.89 8.69,85.58l200.16 0c-1.67,-28.52 -2.69,-57.16 -2.69,-85.58z" />
                <path  {...fil1} d="M1017.85 1884.95c21.21,0 71.27,-80.41 97.13,-234.16l-194.04 0c25.73,153.75 75.82,234.16 96.91,234.16z" />
                <path  {...fil1} d="M907.98 1364.3c-1.67,27.25 -3.06,55.21 -3.06,85.55 0,30.34 1.39,58.33 3.06,85.58l219.86 0c1.76,-27.25 3.15,-55.24 3.15,-85.58 0,-30.34 -1.39,-58.3 -3.15,-85.55l-219.86 0z" />
                <path  {...fil1} d="M1246.15 1449.85c0,28.42 -1.02,57.06 -2.66,85.58l200.13 0c5.57,-27.69 8.69,-56.29 8.69,-85.58 0,-29.29 -3.12,-57.87 -8.6,-85.55l-200.22 0c1.64,28.52 2.66,57.13 2.66,85.55z" />
                <path  {...fil1} d="M1017.85 2000.31c-303.01,0 -549.52,-246.93 -549.52,-550.46 0,-303.5 246.51,-550.43 549.52,-550.43 303.1,0 549.64,246.93 549.64,550.43 0,303.53 -246.54,550.46 -549.64,550.46zm377.9 318.04l-755.71 0c-31.76,0 -57.58,-25.82 -57.58,-57.65 0,-31.85 25.82,-57.68 57.58,-57.68l755.71 0c31.85,0 57.61,25.83 57.61,57.68 0,31.83 -25.76,57.65 -57.61,57.65zm375.68 -1767.05l-1507.06 0c-36.74,0 -66.45,29.81 -66.45,66.56l0 1982.06c0,36.74 29.72,66.56 66.45,66.56l1507.06 0c36.77,0 66.48,-29.81 66.48,-66.56l0 -1982.06c0,-36.74 -29.72,-66.56 -66.48,-66.56z" />
                <path  {...fil1} d="M1400.85 1248.94c-47.78,-90.99 -125.45,-163.49 -221.34,-202.18 24.15,57.37 41.56,127.18 52.47,202.18l168.87 0z" />
                <path  {...fil1} d="M1017.85 1014.78c-21.09,0 -71.19,80.45 -96.91,234.16l194.04 0c-25.85,-153.71 -75.92,-234.16 -97.13,-234.16z" />
                <path  {...fil1} d="M1179.51 1853c95.89,-38.72 173.56,-111.22 221.34,-202.21l-168.87 0c-10.92,75 -28.33,144.81 -52.47,202.21z" />
                <path  {...fil1} d="M634.97 1650.79c47.78,90.99 125.42,163.49 221.34,202.21 -24.15,-57.41 -41.56,-127.21 -52.48,-202.21l-168.87 0z" />
                <path  {...fil1} d="M634.97 1248.94l168.87 0c10.92,-75 28.33,-144.81 52.48,-202.18 -95.92,38.72 -173.57,111.19 -221.34,202.18z" />
            </g>
        </SVGFactory>

    )
}


export default PassportFF