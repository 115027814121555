import { ABOUT_US_FIRST_PARAGRAPH, ABOUT_US_HEADER_TITLE, ABOUT_US_HELMET_DESCRIPTION, ABOUT_US_HELMET_TITLE, ABOUT_US_INTRO_DESCRIPTION, ABOUT_US_INTRO_SUBTITLE, ABOUT_US_INTRO_TITLE, ABOUT_US_SECOND_PARAGRAPH, ABOUT_US_THIRD_PARAGRAPH } from "../types";


const en_about_us_page = {//ABOUT US PAGE
    helmet: {
        [ABOUT_US_HELMET_TITLE]: "About Us | Linares Professional Family Services Corp",
        [ABOUT_US_HELMET_DESCRIPTION]: `Welcome to our family service agency located in Miami, Florida! We are a team of dedicated professionals who are committed to providing the highest quality care and support for families in need.`,
    },


    [ABOUT_US_HEADER_TITLE]: 'Welcome to our family service agency located in Miami, Florida!',
    [ABOUT_US_INTRO_TITLE]: 'LINARES PROFESSIONAL FAMILY SERVICE CORP',
    [ABOUT_US_INTRO_SUBTITLE]: 'A Professional Family Service Agency',
    [ABOUT_US_INTRO_DESCRIPTION]: 'We are a team of dedicated professionals who are committed to providing the highest quality care and support for families in need.',
    [ABOUT_US_FIRST_PARAGRAPH]: `Our agency offers a wide range of services to help families navigate life's challenges. 
            We offer tax preparation services to help you file your taxes accurately and efficiently. 
            Our immigration services can assist you with visa applications, green card petitions, and naturalization applications. 
            We also provide vacation travel packages to help you plan the perfect getaway.`,
    [ABOUT_US_SECOND_PARAGRAPH]: `In addition to these services, we offer passport application assistance to help you obtain your passport quickly and easily. 
            Our team can also help you apply for social security benefits, food stamps, cash assistance, and Medicaid. 
            We understand that these services can be overwhelming and complex, so we are here to guide you through the process.`,
    [ABOUT_US_THIRD_PARAGRAPH]: `We also offer unemployment application assistance and healthcare coverage services. 
            Our team can help you navigate the unemployment application process and provide guidance on how to access healthcare coverage.`,




}

export default en_about_us_page;

