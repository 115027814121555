
import { Box, IconButton } from '@chakra-ui/react'
import styles from './Travel.module.css'





import { useState } from 'react'


// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
// And react-slick as our Carousel Lib
import Slider from 'react-slick'
import HeaderCard from '../headerCard';
import { TravelOptionSection } from '../TravleOptionSection';
import { TestimonialWithCurve } from '../testimonials/TestimonialWithCurve'
import { motion } from 'framer-motion'





// Settings for the slider
const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
}



export const Travel = () => {

    const [slider, setSlider] = useState(null)

    // These are the breakpoints which changes the position of the
    // buttons as the screen size changes


    // These are the images used in the slide
    const headerImages = [
        `https://res.cloudinary.com/druyvgiba/image/upload/v1704335440/linaresprocorp/carousel_3_pdnzhi.png`,
        'https://res.cloudinary.com/druyvgiba/image/upload/v1704335926/post_travel_promotion_visuals_ivytsp.jpg',

    ]

    const travelOptions = [
        {
            title: 'Greece',
            cities: 'Mykonos, Santorini & Create',
            options: '14 días | 16 Días con extensión a Athens',
            description: ``,
            mainImage: `https://res.cloudinary.com/druyvgiba/image/upload/v1704336578/linaresprocorp/travel/greece/h62rMl_hxvye7.jpg`,

        },
        {
            title: 'Spain',
            options: '9 días | 12 Días con extensión a Granada & Costa del Sol',
            cities: 'Barcelona, Madrid & Seville',
            mainImage: `https://res.cloudinary.com/druyvgiba/image/upload/v1704337014/linaresprocorp/travel/spain/Park_Geull_Spain_ch1i6j.jpg`,

        },
        {
            title: 'Italy',
            cities: 'Venice, Florence & Rome',
            options: '9 días | 11 Días con extensión a Sorrento peninsula',
            description: ``,
            mainImage: `https://res.cloudinary.com/druyvgiba/image/upload/v1704337292/linaresprocorp/travel/italy/Coliseum_or_Flavian_Amphitheatre_Amphitheatrum_Flavium_or_Colosseo_Rome_Italy_k9lhs1.jpg`,

        },
        {
            title: 'New Yorks',
            cities: 'Estatua de la Libertad, Ellis island Tour',
            description: `Combine las principales atracciones de la ciudad de Nueva York en una visita guiada en autobús y en el ferry de Staten Island. Con los comentarios de su guía
     visite el Rockefeller Center, el puerto marítimo de South Street, el monumento conmemorativo del 11 de septiembre y Wall Street. 
    Tome un viaje en ferry a Staten Island, con vistas a Ellis Island, 
    la Estatua de la Libertad y el Puente de Brooklyn. De vuelta en tierra, 
    visita el Lincoln Center y Central Park antes de concluir este recorrido relámpago.`,
            mainImage: `https://res.cloudinary.com/druyvgiba/image/upload/v1704337243/linaresprocorp/travel/new_york/new_york_cityscape_tourism_concept_photograph_statue_of_liberty_lower_manhattan_skyline_dnji2x.jpg`,

        },
        {
            title: 'Punta Cana',
            cities: 'Barceló Bávaro Palace',
            options: 'Todo Incluido',
            description: ``,
            mainImage: `https://res.cloudinary.com/druyvgiba/image/upload/v1704337081/linaresprocorp/travel/punta_cana/Aerial_drone_view_of_beach_resort_hotels_with_pools_s3b9nk.jpg`

        },
        {
            title: '7 Noches Caribe Oriental',
            cities: 'BASSETERRE, CHARLOTTE AMALIE, COCOCAY',
            option: 'Crucero',
            description: ``,
            mainImage: 'https://res.cloudinary.com/druyvgiba/image/upload/v1704336210/linaresprocorp/travel/cruise/Cruise_ship_docked_in_Castries_Saint_Lucia_Caribbean_Islands._r8gvt3.jpg',

        }

    ]


    return (

        <Box className={styles.travelContainer} >

            <Box position={'relative'} w={'full'} maxH={'100vh'} overflow={'hidden'}>
                {/* CSS files for react-slick */}
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
                {/* Left Icon*
                <IconButton
                    aria-label="left-arrow"
                    colorScheme="messenger"
                    borderRadius="full"
                    position="absolute"
                    left={'2%'}
                    top={'50%'}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickPrev()}>
                    <BiLeftArrowAlt />
                </IconButton>
                {/* Right Icon 
                <IconButton
                    aria-label="right-arrow"
                    colorScheme="messenger"
                    borderRadius="full"
                    position="absolute"
                    right={'2%'}
                    top={'50%'}
                    transform={'translate(0%, -50%)'}
                    zIndex={2}
                    onClick={() => slider?.slickNext()}>
                    <BiRightArrowAlt />
                </IconButton>
                {/* Slider */}
                <Slider lazyLoad={true} {...settings} ref={(slider) => setSlider(slider)} >
                    {headerImages.map((url, index) => (
                        <HeaderCard key={index} image={url} />


                    ))}
                </Slider>
            </Box>


            <Box minH={'50vh'} p={5} position={'relative'}>
                {/* Travel Body*/}
                <Box position={'relative'} left={{ base: '0', xl: '15rem' }} top={{ base: '-5em', xl: '-6em' }} zIndex={100} maxW={['full', null, '7xl', '5xl']}>
                    {travelOptions.map((option, index) => {
                        return (<Box key={option.title}>
                            <motion.div
                            initial={{
                                opacity: 0,
                                // if odd index card,slide from right instead of left
                                x: index % 2 === 0 ? 50 : -50
                            }}
                            whileInView={{
                                opacity: 1,
                                x: 0, // Slide in to its original position
                                transition: {
                                   
                                    duration: 2 // Animation duration
                                }
                            }}
                            viewport={{ once: true }}
                        >
                            <TravelOptionSection {...option} />
                        </motion.div>
                        </Box>)
                    })}
                </Box>
            </Box>
            <TestimonialWithCurve />
        </Box>


    )
}