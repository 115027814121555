'use client'

import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
} from '@chakra-ui/react'



const Testimonial = (props) => {
    const { children } = props

    return <Box>{children}</Box>
}

const TestimonialContent = (props) => {
    const { children } = props

    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: useColorModeValue('white', 'gray.800'),
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    )
}

const TestimonialHeading = (props) => {
    const { children } = props

    return (
        <Heading as={'h3'} fontSize={'xl'}>
            {children}
        </Heading>
    )
}

const TestimonialText = (props) => {
    const { children } = props

    return (
        <Text
            textAlign={'center'}
            color={useColorModeValue('gray.600', 'gray.400')}
            fontSize={'sm'}>
            {children}
        </Text>
    )
}

const TestimonialAvatar = ({
    src,
    name,
    title,
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Avatar src={src} mb={2} size={'2xl'} />
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600}>{name}</Text>
                <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
                    {title}
                </Text>
            </Stack>
        </Flex>
    )
}

export default function TestimonialsSection() {
    return (
        <section id='#testimonials'>
            <Box>
                <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
                    <Stack spacing={0} align={'center'}>
                        <Heading>Nuestros Clientes Dicen</Heading>
                    </Stack>
                    <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing={{ base: 10, md: 4, lg: 10 }}>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>Extremely satisfied with the results</TestimonialHeading>
                                <TestimonialText>
                                    "I recently used the services of this amazing professional for health care coverage and I am extremely satisfied with the results.
                                    Not only did they provide me with excellent service,
                                    but they also went above and beyond to ensure that my needs were met.
                                    Their expertise and attention to detail were truly impressive.
                                    I highly recommend their services to anyone in need of these services. Thank you so much!"
                                </TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={
                                    'https://res.cloudinary.com/druyvgiba/image/upload/v1708396265/linaresprocorp/home_page/nrd-Y9oI7oLSd_c-unsplash_olvdpa.jpg'
                                }
                                name={'Mario Benitez'}
                                title={'Health-Care Coverage'}
                            />
                        </Testimonial>
                        <Testimonial>
                            <TestimonialContent>
                                <TestimonialHeading>Save a significant amount of money</TestimonialHeading>
                                <TestimonialText>
                                    "I had the pleasure of working with Linares Professional Family Service for my tax preparation needs and was extremely satisfied with the experience.
                                    They was knowledgeable, professional, and went above and beyond to ensure that I received the best possible outcome. They was always available to answer my questions and made sure that I understood everything that was happening.
                                    Thanks to Linares, I was able to save a significant amount of money on my taxes. I highly recommend Linares Pro Family Service for anyone in need of tax preparation services."
                                </TestimonialText>
                            </TestimonialContent>
                            <TestimonialAvatar
                                src={
                                    'https://res.cloudinary.com/druyvgiba/image/upload/v1708396667/linaresprocorp/home_page/luca-nicoletti-dzbSQkqZzUw-unsplash_gucdqy.jpg'
                                }
                                name={'Maritza Ceballos'}
                                title={'Tax Preparation'}
                            />
                        </Testimonial>
                     
                            
                    </Stack>
                </Container>
            </Box>
        </section>
    )
}