import esFlag from '@assets/images/languages/es.svg';
import enFlag from '@assets/images/languages/en.svg';
import frFlag from'@assets/images/languages/fr.svg';
import ukFlag from'@assets/images/languages/uk.svg';
const languages = {

    en: {
        name: 'English',
        native: 'English',
        image: enFlag,
    },

    es: {
        name: 'Spanish',
        native: 'Español',
        image: esFlag,
    },

   /* fr: {
        name: 'French',
        native: 'Français',
        image: frFlag,
    },

    uk: {
        name: 'Ukrainian',
        native: 'Українська',
        image: ukFlag,
    },*/
};

export default languages;