
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import MainFooter from './components/footer/MainFooter';
import { Route, Routes, } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { CreditRepairPage } from './pages/CreditRepairPage';
import ScrollToTop from './components/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TaxesPage } from './pages/TaxPage';
import { ImmigrationPage } from './pages/ImmigartionPage';
import { TravelPage } from './pages/TravelPage';
import linaresTheme from './theme';
import '@locales/i18n';// import i18n (needs to be bundled ;))
import { MainNabar } from './components/navbar/NavbarWithPopoverOnAccent/MainNabar';
import { AboutPage } from '@pages/AboutPage';
import { SupportPage } from '@pages/SupportPage';
function App() {
  return (
    <ChakraProvider theme={linaresTheme}>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ScrollToTop />
      <MainNabar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/credit_repair' element={<CreditRepairPage />} />
        <Route path='/taxes' element={<TaxesPage />} />
        <Route path='/immigration' element={<ImmigrationPage />} />
        <Route path='/travel' element={<TravelPage />} />
        <Route path='/support' element={<SupportPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/qr/:from?' element={<AboutPage />} />
      </Routes>


      <MainFooter />

    </ChakraProvider>
  );
}

export default App;
