
import { Badge, Button, IconButton, Image, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import languages from "@locales"
import { Globe2, } from "react-bootstrap-icons"

import i18n from "../../locales/i18n"
import { useEffect, useState } from "react"


const LanguageMenu = ({ ...props }) => {
    const [language, setLanguage] = useState({})
    useEffect(() => {
        try {
            setLanguage(languages[i18n.resolvedLanguage])
           
        } catch (error) {
            console.log(error)
        }

    }
        , [i18n.resolvedLanguage])
    return (<Menu>
        <MenuButton p={2} as={Button} title="Languages" leftIcon={!!language?.image ? <Image
            boxSize='2rem'
            borderRadius='full'
            src={language?.image}
            alt={language?.name}
            mr='12px'
        /> : <Globe2 size={'2rem'} {...props} />} >{language?.native||'Language'}</MenuButton>
        <MenuList >
            {Object.entries(languages)?.map(([lng, value], index) =>
                <MenuItem key={`${lng}-${index}}`} minH='48px' onClick={() => {
                    i18n.changeLanguage(lng)
                    window.location.reload();
                }}>
                    <Image
                        boxSize='2rem'
                        borderRadius='full'
                        src={value?.image}
                        alt={value?.name}
                        mr='12px'
                    />
                    <Badge colorScheme={i18n.resolvedLanguage === lng ? 'green' : 'black'} size={'sm'} mx={2}>{lng}</Badge>
                    <span>{value?.native}</span>
                </MenuItem>)}
        </MenuList>
    </Menu>)
}

export default LanguageMenu;