import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react'
import { CategoryCard } from '@components/cards/CatetgoryCard'
import { categories } from './_data'
import { useNavigate } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { HOME_SERVICES_HEADER_TITLE } from 'src/locales/types'

export const ServiceCardSection = () => {
  const transPath = "page.home.section.services"
  const navigate = useNavigate()
  return (<Box
    bg={useColorModeValue('gray.100', 'gray.700')}
    px={{
      base: '2',
      md: '8',
      lg: '10vw',
    }}
    py={{
      base: '6',
      md: '8',
      lg: '12',
    }}
    position={'relative'}
  >

    <Center w={'full'}>
      
        <Heading fontSize={{ base: 'lg', md: '2xl', lg: '4xl' }}  >
          <Trans i18nKey={`${transPath}.${HOME_SERVICES_HEADER_TITLE}`} />
        </Heading>
        
    
    </Center>

    <Container maxW={{ base: '7xl', md: '4xl', '2xl': '7xl' }} >

      <Stack
        justify={'center'}
        spacing={{
          base: '6',
          md: '8',
          lg: '12',
        }}
      >
        <Flex
          justify="space-between"
          align={{
            base: 'start',
            md: 'center',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
        >



        </Flex>
        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            '2xl': 3
          }}
          gap={{
            base: '6em',
            md: '4em',
            lg: '6em',
          }}
        >
          {categories.map((category) => (
            <CategoryCard key={category.name} category={category} onClick={() => navigate(`${category?.url}`)} />
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
  )
}