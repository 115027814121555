import { Box, Button, Flex, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { BsFillSendFill  } from 'react-icons/bs'
import { ChatTextarea } from './ChatTextarea'
import { EmptyStatePrompt } from './EmptyStatePrompt'
import { PromptSuggestButton } from './PromptSuggestButton'
import { WhatsAppIcon } from '@assets/icons/SocialIcons'

import CreditScoreFF from '@components/coroinside-icon/CreditScoreFF'
import ImmigrationFF from '@components/coroinside-icon/ImmigrationFF'
import RelaxFF from '@components/coroinside-icon/RelaxFF'
import AccountingFF from '@components/coroinside-icon/AccountingFF'
import { useState } from 'react'

export const WhatsappChat = ({ onClose=()=>{}, maxMessageLength = 200, prompSuggestion = [

  {
    icon: <CreditScoreFF />,
    title: "Credit Repair",
    description: "Professional credit counseling and support",
    id: "CR_PCCS",
  },

  {
    icon: <ImmigrationFF />,
    title: "Immigration",
    description: "Assistance with paperwork and documentation",
    id: "IMM_AWPD",
  },
  {
    icon: <ImmigrationFF />,
    title: "Immigration",
    description: "Green Card (Permanent Residence)  application assistance",
    id: "IMM_GCA",
  },
  {
    icon: <RelaxFF />,
    title: "Travel",
    description: "Customized travel itineraries based on your preferences and budget",
    id: "TRV_CTIB",
  },
  {
    icon: <AccountingFF />,
    title: "Tax Preparation",
    description: "Accurate and reliable tax preparation services",
    id: "TXP_ATXPS",
  },
] }) => {
  const [message, setMessage] = useState('')
  const [apiWhatsappLink, setAPIWhatsappLink] = useState(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_BUSINESS_PHONE_WHATSAPP}&text=I%20need%20help`)


  const handleSelectPrompt = (id) => {
    const description = prompSuggestion.find(item => item.id === id)?.description || '';
    setMessage(description)
    handleSendWhatsappMessage(description)
  }
  const handleChangeText = (evt) => {
    const value = evt.target.value;
    if (value?.length > maxMessageLength)
      return;
    if (/^\d+$/.test(value)) setMessage(`Please enter a valid message.`);
    else setMessage(value.trimStart());
    handleSendWhatsappMessage(value.trimStart());

  }


  const handleSendWhatsappMessage = (value) => {

    // Checks whether the length of the  text exceeds the limit   
    if (message.length > maxMessageLength) {
      alert(`The length should not exceed ${maxMessageLength} characters`);
      return false;
    }
    setAPIWhatsappLink(`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_BUSINESS_PHONE_WHATSAPP}&text=${value}`)

  };





  return (
    <Flex direction="column" zIndex={'toast'} p={5}  rounded={'xl'}>
      <Box mb={'15%'} >
        <EmptyStatePrompt icon={WhatsAppIcon}>How can I help you?</EmptyStatePrompt>
      </Box>
      <Box
        bottom="0"
        insetX="0"
      
        paddingY="8"
        marginX="4"
      >
        <Stack mx="auto">
          <SimpleGrid columns={2} spacing="2">
            {prompSuggestion.map(suggestion =>
              <PromptSuggestButton  key={suggestion?.id} {...suggestion} onClick={handleSelectPrompt} />
            )}
          </SimpleGrid>

          <Box as="form" pos="relative" pb="1" >
            <ChatTextarea rows={1} value={message} onChange={handleChangeText} />
            <Flex width={'full'} justifyContent={'flex-end'} pr={6}>
              <Text color={'fg.muted'} fontSize={'xs'}>{message?.length}/{maxMessageLength}</Text>
            </Flex>
            <Box pos="absolute" top="3" right="0" zIndex="2">
              <Button as={Link}  v target="_blank" href={apiWhatsappLink} size="sm" variant="link" colorScheme="gray">
                <BsFillSendFill />
              </Button>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Flex>
  )
}
