import { MF_PAGE_LINK_ABOUT_US, MF_PAGE_LINK_CONTACT, MF_PAGE_LINK_COOKIES_POLICY, MF_PAGE_LINK_DISCLAIMER, MF_PAGE_LINK_PRIVACY_POLICY, MF_PAGE_LINK_TERMS_OF_USE, MF_PAGE_LINK_TITLE_LINARES, MF_PAGE_LINK_TITLE_SERVICES } from "../types";



const esMainFooter = {
       [MF_PAGE_LINK_TITLE_LINARES]: "Linares",
       [MF_PAGE_LINK_TITLE_SERVICES]: "Servicios",

       [MF_PAGE_LINK_ABOUT_US]:"Acerca de Nosotros" ,
       [MF_PAGE_LINK_CONTACT]:"Contáctenos",
       [MF_PAGE_LINK_TERMS_OF_USE]:"Términos y Condiciones",
       [MF_PAGE_LINK_PRIVACY_POLICY]: "Política de Privacidad",
       [MF_PAGE_LINK_DISCLAIMER]: "Descripción Legal",
       [MF_PAGE_LINK_COOKIES_POLICY]: "Política de Cookies",
   
   
   
}


export default esMainFooter;
