import WhatsappButton from "@components/buttons/WhatsappButton"
import ContactSections from "../components/contact/ContactSection"
import TestimonialsSection from "../components/testimonials/Testimonials"
import { ServiceCardSection } from "../layouts/HomePageLayouts/ServiceCardSection"
import { WhatsappChat } from "@components/conversational/WhatsappChat"
import OfficesInfoSection from "@layouts/OfficesInfoSection"




export const HomePage = () => {

    return (<>
        <ContactSections />
        <ServiceCardSection/>
        <OfficesInfoSection/>
        <TestimonialsSection />
        <WhatsappButton/>
      
    </>
    )
}