
// 1. Import the default chakra ui PRo Theme
import { theme } from '@chakra-ui/pro-theme'
import {linaresTheme } from '@themes/linaresTheme' 
// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"


// 2. Call `extendTheme` and pass your custom values

const proTheme = extendTheme(theme)
const extenstion = {
  components: {
    ...proTheme.components,
    Heading: {
      ...proTheme.components.Heading,
      ...linaresTheme.components.Heading
    }
  },

  fonts: {
    ...proTheme.fonts,
    ...linaresTheme.fonts
  },
  colors: {
    ...proTheme.colors, brand: linaresTheme.brand.colors.secondary
  },
}
const linaresProTheme = extendTheme(extenstion, proTheme)


export default linaresProTheme;

