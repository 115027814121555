
import { Button,  Center, Box, Image } from '@chakra-ui/react'
export default function HeaderCard({ image }) {
    return (

        <Box w={'full'}
            justify={'flex-end'}
            maxH={'80vh'}
        >
            <Image
                maxH={'80vh'}
                w={'full'}
                zIndex={2}
                position={'relative'}
                objectFit='fill'
                src={image}
                alt='Linares Pro corp'
            />
          

        </Box>

    )
}


