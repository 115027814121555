import { useColorMode } from "@chakra-ui/react"


export default function SVGFactory({ children, svgProps = {} }) {
 
    return (<svg xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        version="1.1"
        width="50"
        height="50"
        viewBox="0 0 3000 3000"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...svgProps}
       
    >
        {children}
    </svg>)


}