import { HOME_HELMET_DESCRIPTION, HOME_HELMET_TITLE, HOME_SERVICES_HEADER_TITLE } from "../types";



const es_home_page = {//   
        helmet: {
                [HOME_HELMET_TITLE]: "Linares Professional Family Services Corp",
                [HOME_HELMET_DESCRIPTION]: ``,
        },
        section: {//Sections  within the Home page

                services: {//Main  Section of the Home Page
                        [HOME_SERVICES_HEADER_TITLE]: 'Nuestros Servicios',
                },
        }


}

export default es_home_page;

