

import { Avatar, Box, Button, CloseButton, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, keyframes, useDisclosure } from '@chakra-ui/react'
import { WhatsAppIcon } from '@assets/icons/SocialIcons'
import { WhatsappChat } from '@components/conversational/WhatsappChat'
export default function WhatsappButton() {

    const size = { base: '45px', lg: '65px' }
    const color = 'teal'
    const { onOpen, onClose, isOpen } = useDisclosure()
    const pulseRing = keyframes`
	0% {
    transform: scale(0.35);
  }
  50%,
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
	`

    return (
        <Box  >
            <Box
                as="div"
                display={isOpen ? 'none' : 'flow'}
                onClick={onOpen}
                right={{ base: 5, md: 10 }}
                bottom={{ base: 2, md: 10 }}
                position="fixed"
                top={isOpen ? '15vh' : 'none'}
                zIndex='tooltip'
                w={size}
                h={size}
                _before={{
                    content: "''",
                    position: 'relative',
                    display: 'block',
                    width: '300%',
                    height: '300%',
                    boxSizing: 'border-box',
                    marginLeft: '-100%',
                    marginTop: '-100%',
                    borderRadius: '50%',
                    bgColor: color,
                    animation: `2.25s ${pulseRing} cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`,
                }}>
                <IconButton variant={'unstyled'} icon={<WhatsAppIcon boxSize={size} />} position="absolute" top={0} />

            </Box>

            <Modal
                isCentered
                motionPreset='slideInBottom'
                isOpen={isOpen}
              size={'6xl'}
                onClose={onClose}
            
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                />
                <ModalContent >
                    <ModalCloseButton />
                    <ModalBody>
                        <WhatsappChat />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </Box >
    )
}