import { ACCESSIBLE_DESCRIPTION, ACCESSIBLE_HEADER, ADMIN_DASHBOARD_DESCRIPTION, ADMIN_DASHBOARD_HEADER, BUSINESS_PROMOTION_DESCRIPTION, BUSINESS_PROMOTION_HEADER, DASHBOARD_BUTTON, FEATURES_SECTION_HEADER, LOGIN_FORM_GOOGLE_BUTTON, LOGIN_FORM_ISSUES_OPTION, LOGIN_FORM_ISSUES_QUESTION, LOGIN_FORM_LABEL_EMAIL, LOGIN_FORM_LABEL_PASSWORD, LOGIN_FORM_NOT_REGISTERED_OPTION, LOGIN_FORM_NOT_REGISTERED_QUESTION, LOGIN_FORM_PLACEHOLDER_EMAIL, LOGIN_FORM_PLACEHOLDER_PASSWORD, LOGIN_FORM_REMEBER_ME_LABEL, LOGIN_FORM_SUBMIT_BUTTON, LOGIN_FORM_TITLE, ONLINE_BOOKING_DESCRIPTION, ONLINE_BOOKING_HEADER, PAYMENT_DESCRIPTION, PAYMENT_HEADER, SMS_EMAIL_NOTIFICATIONS_DESCRIPTION, SMS_EMAIL_NOTIFICATIONS_HEADER, TEMPLATES_BUTTON, WEBSITE_TEMPLATES_DESCRIPTION, WEBSITE_TEMPLATES_HEADER, WELCOME_BUSINESS_DESCRIPTION, WELCOME_BUSINESS_HEADER } from "../types";

import CR_page from "./esCreditRepairPage";
import about_us_page from "./esAboutUsPage";
import es_home_page from "./esHomePage";

const enPages = {

    home: {...es_home_page},
    credit_repair: { ...CR_page },
    about_us: { ...about_us_page },//  About Us Page




    login: {//Login  and Registration Pages

        form: {

            [LOGIN_FORM_TITLE]: 'Inicia sesión en tu cuenta',
            [LOGIN_FORM_NOT_REGISTERED_QUESTION]: "¿No tienes una cuenta?",
            [LOGIN_FORM_NOT_REGISTERED_OPTION]: '¡Regístrate aquí!',
            [LOGIN_FORM_ISSUES_QUESTION]: "¿Tiene algún problema con el proceso de autenticación?",
            [LOGIN_FORM_ISSUES_OPTION]: 'Contáctenos',
            [LOGIN_FORM_REMEBER_ME_LABEL]: 'Recorder este  dispositivo',
            [LOGIN_FORM_LABEL_EMAIL]: 'Email',
            [LOGIN_FORM_PLACEHOLDER_EMAIL]: 'Introduzca su  email...',
            [LOGIN_FORM_LABEL_PASSWORD]: 'Contraseña',
            [LOGIN_FORM_PLACEHOLDER_PASSWORD]: 'Introduzca  su contraseña...',
            [LOGIN_FORM_SUBMIT_BUTTON]: 'Enviar',
            [LOGIN_FORM_GOOGLE_BUTTON]: 'Iniciar Sesión con Google',
        },

        /* errorMessages: {
             [WRONG_CREDENTIALS]: WRONG_USERNAME_OR_PASSWORD,
             [EMPTY_FIELD]: FIELD_IS_REQUIRED,
             [NO_MATCH_PASSWORDS]: PASSWORDS_DO_NOT_MATCH,
             [USERNAME_ALREADY_EXISTS]: THIS_USERNAME_ALREADYS_EXISTS,
             [EMAIL_ALREADY_USED]: EMAIL_ALREADY_REGISTERED,
         }*/
    }




}


export default enPages;