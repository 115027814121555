
import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Divider,
    VStack,
    Link,
    HStack,
} from '@chakra-ui/react'
import promotionVideo from '../../assets/Promotion.mp4';
import { MdEmail, MdLocationOn, MdPhone } from 'react-icons/md';

export default function ContactSections() {


    return (
        <Container maxW={'8xl'}>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 20, md: 28 }}
                direction={{ base: 'column', md: 'row' }}>
                <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
                        <Text
                            color={'facebook.500'}>
                            Mantente en Contacto.
                        </Text>
                        <br />
                        <Text as={'span'} color={'orange.400'}>
                            ¡Ap&oacute;yanos!
                        </Text>
                    </Heading>

                    <Divider />
                    <VStack pl={0} spacing={3} alignItems="flex-start" justifyContent={'flex-start'}  >
                        <Button
                            aria-label='Call to Linares Pro Corp Office'
                            size="md"
                            height="48px"
                            width="300px"
                            variant="ghost"
                            as={'a'}
                            href={`tel:+1${process.env.REACT_APP_BUSINESS_PHONE_OFFICE}`}
                            _hover={{ border: '2px solid #1C6FEB' }}
                            justifyContent={'start'}
                            leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                            {process.env.REACT_APP_BUSINESS_PHONE_OFFICE}
                        </Button>
                        <Button
                            aria-label='Call to Linares Pro Corp WhatsApp'
                            size="md"
                            height="48px"
                            width="300px"
                            variant="ghost"
                            as={'a'}
                            href={`tel:+1${process.env.REACT_APP_BUSINESS_PHONE_WHATSAPP}`}

                            _hover={{ border: '2px solid #1C6FEB' }}
                            justifyContent={'start'}
                            leftIcon={<MdPhone color="#1970F1" size="20px" />}>
                            {process.env.REACT_APP_BUSINESS_PHONE_WHATSAPP}
                        </Button>

                        <Button
                            size="md"
                            height="48px"
                            width="300px"
                            as={'a'}
                            href={`mailto:${process.env.REACT_APP_BUSINESS_EMAIL_SUPPORT}`}
                            variant="ghost"
                            justifyContent={'start'}
                            _hover={{ border: '2px solid #1C6FEB' }}
                            leftIcon={<MdEmail color="#1970F1" size="20px" />}>
                            {process.env.REACT_APP_BUSINESS_EMAIL_SUPPORT}
                        </Button>
                        <HStack>
                            <MdLocationOn color="#1970F1" size="20px" />
                            <Link

                                target='_blank'
                                maxW={'300px'}
                                rel="noopener noreferrer"
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    window.open(process.env.REACT_APP_GOOGLE_BUSINESS_LINK, '_blank')
                                }}
                                href={process.env.REACT_APP_GOOGLE_BUSINESS_LINK}
                            >
                                {process.env.REACT_APP_BUSINESS_ADDRESS}
                            </Link>
                        </HStack>

                    </VStack>

                </Stack>
                <Flex
                    flex={1}
                    justify={'center'}
                    align={'center'}
                    position={'relative'}
                    w={'full'}>

                    <Box
                        position={'relative'}
                        rounded={'xl'}
                        width={'full'}
                        overflow={'hidden'}>
                        <video playsInline autoPlay loop controls={false} muted poster="">
                            <source src={promotionVideo} type="video/mp4"></source>
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                </Flex>
            </Stack>

            <div id="map"></div>


        </Container >
    )
}

