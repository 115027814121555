import { HOME_HELMET_DESCRIPTION, HOME_HELMET_TITLE, HOME_SERVICES_HEADER_TITLE } from "../types";



const en_home_page = {//   
        helmet: {
                [HOME_HELMET_TITLE]: "Linares Professional Family Services Corp",
                [HOME_HELMET_DESCRIPTION]: `Welcome to our family service agency located in Miami, Florida! We are a team of dedicated professionals who are committed to providing the highest quality care and support for families in need.`,
        },
        section: {//Sections  within the Home page

                services: {//Main  Section of the Home Page
                        [HOME_SERVICES_HEADER_TITLE]: 'Our Services',
                },
        }


}

export default en_home_page;

