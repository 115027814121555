import { NAVBAR_LINK_ABOUT_US, NAVBAR_LINK_CONTACT_US, NAVBAR_LINK_CREDIT_REPAIR, NAVBAR_LINK_HOME, NAVBAR_LINK_IMMIGRATION, NAVBAR_LINK_PASSPORT, NAVBAR_LINK_SERVICES, NAVBAR_LINK_SUPPORT, NAVBAR_LINK_TAXES, NAVBAR_LINK_TRAVEL } from "../types";


const enComponents = {
    navbar: {
        [NAVBAR_LINK_HOME]: "Home",
        [NAVBAR_LINK_IMMIGRATION]: "Immigration",
        [NAVBAR_LINK_CONTACT_US]: "Contact Us",
        [NAVBAR_LINK_ABOUT_US]: "About Us",
        [NAVBAR_LINK_SUPPORT]: "Support",

        [NAVBAR_LINK_SERVICES]: "Services",
        [NAVBAR_LINK_PASSPORT]: "Passport",
        [NAVBAR_LINK_TAXES]: "Taxes",
        [NAVBAR_LINK_TRAVEL]: "Travel",
        [NAVBAR_LINK_CREDIT_REPAIR]: "Credit Repair",
        [NAVBAR_LINK_CONTACT_US]: "Contact",
     
       
    }
}


export default enComponents;

