
import { Icon, Image } from '@chakra-ui/react';
import { APIProvider, AdvancedMarker, Map, Marker, useMapsLibrary, } from '@vis.gl/react-google-maps';

import { useEffect, useState } from 'react';
import { FaAd } from 'react-icons/fa';
import logo from '@assets/logo512.png';
// Make sure to add your Google Maps API key to your environment variables.

export const ShwowPlaceInMap = ({ ...props }) => {

  const [zoom, setZoom] = useState(12);
  const location = { "title": "Linares Pro Family Service Corp", "address1": "6001 NW 153rd St", "address2": "Miami Lakes, FL, United States", "coords": { "lat": 25.9127174, "lng": -80.29946 }, "placeId": "ChIJ3-Q0zgnyPi0RJl9wQOt3DI0" }
  const position = { address: "6001 NW 153rd St, Miami Lakes, FL, 33014", "lat": 25.9127183114951, "lng": -80.29945932115999 };
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_ID;
 

  function Geocoding() {
    const geocodingLibrary = useMapsLibrary('geocoding');
    useEffect(() => {
      if (!geocodingLibrary) return;

      const geocoder = new geocodingLibrary.Geocoder();
      // ...
    }, [geocodingLibrary]);
  }

  return (<APIProvider apiKey={API_KEY}>
    <Map
      defaultZoom={zoom}
      defaultCenter={position}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      controlSize={30}
      maxZoom={20}
      minZoom={10}
      mapId={MAP_ID}
      style={{ width: '100%', height: '100%' }}
      {...props}
    >

      <AdvancedMarker
        title="Linares Pro Family Service Corp"
        position={position}>
        <Image
          boxSize='45px'
          objectFit='cover'
          src={logo}
          alt='Linares Logo'
        />
      </AdvancedMarker>
      <Geocoding />

    </Map>
  </APIProvider>);
};
