import { Box, Button, Container, Heading, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react'

export const CtaWithImage = () => (
  <Box bg="bg.surface">
    <Container
      py={{
        base: '16',
        md: '24',
      }}
    >
      <Stack
        direction={{
          base: 'column',
          md: 'row',
        }}
        spacing={{
          base: '12',
          lg: '16',
        }}
      >
        <Stack
          spacing={{
            base: '8',
            md: '10',
          }}
          width="full"
          justify="center"
        >
          <Stack
            spacing={{
              base: '4',
              md: '6',
            }}
          >
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                                <Text
                                    as={'span'}
                                    position={'relative'}
                                    color={'green.400'}
                                    >
                                   ¿Estás Preparado
                                </Text>
                               
                                <Text color={'blue.400'} as={'span'}>
                                   {' '} para tu reembolso?
                                </Text>{' '}
                            </Heading>
            <Text
              fontSize={{
                base: 'lg',
                md: 'xl',
              }}
              color="fg.muted"
            >
              En <strong>Linares Pro Family Service Corp</strong> sabemos que tu prioridad es recibir el máximo reembolso, por eso
              nuestros profesionales y expertos de taxes bilingües saben cómo prepara tu declaración de taxes para que tú solamente te encargues de lo que en realidad necesitas.
            </Text>
          </Stack>
          <Stack
            direction={{
              base: 'column-reverse',
              md: 'row',
            }}
            spacing="3"
          >
            <Button variant="secondary" size="xl">
              Learn more
            </Button>
            <Button size="xl">Start free trial</Button>
          </Stack>
        </Stack>
        <Image
        rounded={'lg'}
          width="full"
          height={{
            base: 'auto',
            md: 'lg',
          }}
          objectFit="cover"
          src="https://res.cloudinary.com/druyvgiba/image/upload/v1704756711/linaresprocorp/taxes/1040formlaptop_jr9kyh.jpg"
        />
      </Stack>
    </Container>
  </Box>
)
