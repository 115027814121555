
import {
    Button,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
//'https://res.cloudinary.com/druyvgiba/image/upload/v1704756711/linaresprocorp/taxes/1040formlaptop_jr9kyh.jpg'
import { CardWithForm } from '../cards/CardWithForm'
import { CtaWithImage } from '../cta/CtaWhitImage'
export default function TaxesSection() {
    return (
        <section id='#taxes'>
                <CtaWithImage />
                <CardWithForm />
        </section>
    )
}