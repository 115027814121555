export const categories = [
  {
    name: 'Credit Repair',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475074/linaresprocorp/credit/Credit_Repair_i96ghm.jpg',
    url: '/credit_repair',
  },
  {
    name: 'Tax Prepare',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1714695956/linaresprocorp/home_page/Tax_Prepare_tiskyb.jpg',
    url: '#',
  },
  {
    name: 'Immigration',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475076/linaresprocorp/home_page/immigration_z6mgh7.jpg',
    url: '#',
  },
  {
    name: 'Travel',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475079/linaresprocorp/home_page/travel_dwpikt.jpg',
    url: '/travel',
  },
  {
    name: 'Marriage & Simple Divorce',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475077/linaresprocorp/home_page/marriage_and_divorce_ixvgcx.jpg',
    url: '#',
  },
  {
    name: 'Unemployment/Unexpected Loss of Income',
    imageUrl:'https://res.cloudinary.com/druyvgiba/image/upload/v1708475080/linaresprocorp/home_page/unemployment_claim_farzcl.jpg',
    url: '#',
  },
  {
    name: 'Children & Family',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475073/linaresprocorp/home_page/children_and_family_irwpfi.jpg',
    url: '#',
  },

 
  {
    name: 'Health/Obama-Care coverage',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475075/linaresprocorp/home_page/healthcare_r6vfvx.jpg',
    url: '#',
  },
  {
    name: 'Plan 8 with low Income Housing',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708477846/linaresprocorp/home_page/AdobeStock_2640223_xfk4ap.jpg',
    url: '#',
  },
  {
    name: 'Social Security Benefits',
    imageUrl:
      'https://res.cloudinary.com/druyvgiba/image/upload/v1708475078/linaresprocorp/home_page/social_security_mnuhkz.jpg',
    url: '#',
  },
]
