import {
    Box, Button, Center, Img,
    LightMode, VisuallyHidden,
    Circle, Divider, Flex, Heading, Stack, Tag, Text, useColorModeValue as mode,
} from '@chakra-ui/react';
import { ShwowPlaceInMap } from '@pages/Maps/ShwowPlaceInMap';
import React, { useEffect, useState } from 'react';
import { BsGoogle } from 'react-icons/bs';

import { FaRoute } from 'react-icons/fa'

const OfficesInfoSection = () => {
    const [todayHours, setTodayHours] = useState({})
    const [status, setStatus] = useState({})
    useEffect(() => {
        const getAvailableStatus = (info) => {
            const date = new Date();
            const day = date.getDay();
            const hour = date.getHours();
            const hoursLeftToday = () => {
                return info?.closeT - hour;
            }

            if (day === 0 || day === 5) {
                const isTomorrowOpen = businessHours[day === 0 ? 'monday' : 'saturday'].open;
                return { status: 'Closed', message: isTomorrowOpen ? `Our office will be open tomorrow at ${isTomorrowOpen}` : 'We are closed' };
            }
            else if (hour < info?.openT || hour >= info?.closeT) {
                return { status: 'Closed', message: `We will open at ${info?.open}` };
            }
            else if (hoursLeftToday() <= 1) {
                return { status: 'Open', message: `We are closing soon`, hoursLeftToday: hoursLeftToday() };
            }
            else {
                return { status: 'Open', message: 'We are open ', hoursLeftToday: hoursLeftToday() };
            }

        };
        setStatus(getAvailableStatus(businessHours[todayHours.day]))
    }
        , [todayHours])

    useEffect(() => {
        const date = new Date();
        let dayOfWeek;
        switch (date.getDay()) {
            case 0: dayOfWeek = "sunday"; break;
            case 1: dayOfWeek = "monday"; break;
            case 2: dayOfWeek = "tuesday"; break;
            case 3: dayOfWeek = "wednesday"; break;
            case 4: dayOfWeek = "thursday"; break;
            case 5: dayOfWeek = "friday"; break;
            case 6: dayOfWeek = "saturday"; break;
        }
        setTodayHours({ day: dayOfWeek, hours: date.getHours() });
    }, []);
    const businessHours = {
        monday: {
            open: "6:00 PM",
            close: "10:00 PM",
            openT: 18, // Open at 6PM on Thursdays instead of the usual 7PM.
            closeT: 22, // Close at 10PM on Thursdays instead of the usual 9PM.

        },
        tuesday: {
            open: "6:00 PM",
            close: "10:00 PM",
            openT: 18, // Open at 6PM on Thursdays instead of the usual 7PM.
            closeT: 22, // Close at 10PM on Thursdays instead of the usual 9PM.
        },
        wednesday: {
            open: "6:00 PM",
            close: "10:00 PM",
            openT: 18, // Open at 6PM on Thursdays instead of the usual 7PM.
            closeT: 22, // Close at 10PM on Thursdays instead of the usual 9PM.
        },
        thursday: {
            open: "6:00 PM",
            close: "10:00 PM",
            openT: 18, // Open at 6PM on Thursdays instead of the usual 7PM.
            closeT: 22, // Close at 10PM on Thursdays instead of the usual 9PM.
        },
        friday: {
            open: "Closed",// Closed on Fridays.

            openT: 0,
            closeT: 0
        },
        saturday: {
            open: "10:00 AM",
            close: "5:00 PM",
            openT: 10,
            closeT: 17,

        },
        sunday: {
            open: "Closed",// Closed on Sundays.
            openT: 0,
            closeT: 0
        },
    }




    return (
        <Box as="section" bg="gray.800" color="white" py="7.5rem">
            <Box mx="auto" px={{ base: '6', md: '8' }}>
                <Box textAlign="center" my={6}>
                    <Heading
                        as="h1"
                        size="3xl"
                        fontWeight="extrabold"
                        maxW="48rem"
                        mx="auto"
                        lineHeight="1.2"
                        letterSpacing="tight"
                    >
                        Find Us
                    </Heading>
                    <Text fontSize="xl" mt="4" maxW="xl" mx="auto">
                        Our office hours and location information for easy access.
                    </Text>
                </Box>






                <Center>
                    <Stack
                        maxW={'2xl'}
                        zIndex={3}
                        style={{ "WebkitBackdropFilter": "blur(5px)" }}
                        border='1px solid rgba(62, 55, 55, 0.3)'
                        spacing="8"
                        borderWidth="1px" rounded="lg" padding="8" width="full">
                        <Center>
                            <Heading size="md" color={'whiteAlpha.900'}>Business Hours</Heading>
                        </Center>
                        <Stack spacing="6" divider={<Divider />}>
                            {Object.keys(businessHours).map((day, i) => (
                                <DayInfoItem key={i} day={day} isActive={todayHours?.day === day} >
                                    <Text pt={1} borderRight={todayHours?.day === day ? 'solid 5px blue' : ''} pr={2} m={0} fontSize={{ base: "sm", sm: 'md', md: "lg", lg: "xl", }} color={'whiteAlpha.900'}> {businessHours[day].open}  {!!businessHours[day]?.close ? `- ${businessHours[day]?.close}` : ''}</Text>
                                </DayInfoItem>
                            ))}

                        </Stack>
                        <Center>
                            <Tag variant={'outline'} colorScheme={status.status === 'Open' ? 'whatsapp' : 'linkedin'} size="lg" fontSize={{ base: "md", sm: "lg", md: "xl", lg: "3xl", xl: "4xl" }}>
                                {status.message}
                            </Tag>
                        </Center>
                        <Stack
                            justify="center"
                            direction={{ base: 'column', md: 'row' }}
                            mt="10"
                            mb="20"
                            spacing="4"
                        >
                            <LightMode>
                                <Button
                                    onClick={() => window.open(process.env.REACT_APP_GOOGLE_BUSINESS_LINK, '_blank')}

                                    fontSize={'sm'}
                                    rounded={'full'}
                                    colorScheme='linkedin'
                                    color={'white'}
                                    boxShadow={
                                        '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                                    }
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    _focus={{
                                        bg: 'blue.500',
                                    }}
                                    leftIcon={<BsGoogle />}
                                >
                                    Open in Google Maps
                                </Button>

                            </LightMode>
                        </Stack>
                        <Flex flex={1} justifyContent={'center'} alignItems={'center'}>
                            <Box h={'lg'} maxH={'xl'} w={'xl'} position={'relative'} >
                                <ShwowPlaceInMap />
                                <Circle
                                    onClick={() => window.open(process.env.REACT_APP_GOOGLE_BUSINESS_LINK, '_blank')}
                                    _hover={{ bg: 'blue.500', cursor: 'pointer', color: 'white' }}
                                    size="20"
                                    as="button"
                                    bg="white"
                                    shadow="lg"
                                    color="blue.600"
                                    position="absolute"
                                    bottom="1%"
                                    left="50%"
                                    transform="translate3d(-50%, -50%, 0)"
                                    fontSize="xl"
                                    transition="all 0.2s"
                                    _groupHover={{
                                        transform: 'translate3d(-50%, -50%, 0) scale(1.05)',
                                    }}
                                >
                                    <VisuallyHidden>Open in Google Maps</VisuallyHidden>
                                    <FaRoute />
                                </Circle>

                            </Box>

                        </Flex>
                    </Stack>
                </Center>

            </Box>


        </Box>



    );
};

export default OfficesInfoSection;



const DayInfoItem = (props) => {

    const { day, hours, children, isActive = false } = props
    return (
        <Flex justify="space-between" fontSize="sm">

            <Text pl={2} borderLeft={isActive ? 'solid 5px blue' : ''} fontSize={{ base: "lg", md: "xl", lg: "2xl", }} color={'whiteAlpha.800'} textTransform={'capitalize'} fontWeight="medium"  >
                {day?.trim()}
            </Text>

            {!!hours ? <Text fontWeight="medium">{hours}</Text> : children}
        </Flex>
    )
}

