import { useColorMode } from "@chakra-ui/react"
import SVGFactory from "./SVGFactory"


const RelaxFF = ({
    size = 52, // or any default size of your choice
    color,
    presetColor = true // or any color of your choice

}) => {
    const { colorMode } = useColorMode()
    const adaptativeColor = color ? color : colorMode === 'dark' ? 'white' : 'black'
    const fil0 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil1 = { fill: presetColor ? '#354878' : adaptativeColor, fillRule: 'nonzero' }
    const fil2 = { fill: presetColor ? '#FFA500' : adaptativeColor, fillRule: 'nonzero' }
    return (
        <SVGFactory >
            <g >
                <path {...fil0} d="M74.31 1159.06c70.39,-14.13 199.1,-31.05 307.71,-4.72 -100.8,81.83 -99.14,224.78 -89.56,298.15 2.58,17.83 22.59,23.42 32.17,9.37 37.38,-53.64 115.59,-145.76 218.97,-156.13 18.32,-0.97 36.56,-3.76 53.05,-5.59 -157.24,461.67 -141.69,763.42 -115.59,983.53 -218.16,69.59 -372.85,230.39 -372.85,289.61 0,77.17 258.15,26.32 577.07,26.32 148.61,0 284.21,12.25 385.85,16.94 -9.5,-15.98 -16.5,-31.02 -20.85,-44.18 -19.06,-50.85 -54.7,-167.39 -81.71,-259.59 -61.63,-27.2 -131.18,-47.94 -204.23,-57.31 -65.13,-215.41 -131.18,-481.41 3.5,-957.21 21.74,2.8 44.31,5.59 67.81,7.47 103.43,10.37 181.67,102.49 218.98,156.13 9.58,14.05 30.48,8.46 32.17,-9.37 9.58,-73.38 11.32,-216.32 -89.48,-298.15 108.62,-26.32 237.24,-9.41 307.61,4.72 19.16,3.76 31.32,-19.74 19.16,-35.75 -53.89,-69.59 -168.66,-192.79 -319.03,-209.71 57.41,-36.66 121.7,-54.55 164.34,-62.98 18.25,-2.82 23.43,-29.17 8.69,-41.42 -73.08,-54.52 -230.34,-142.94 -374.61,-29.14 -50.39,39.51 -86.87,81.81 -113.85,123.17 -26.09,-41.36 -63.39,-83.67 -112.96,-123.17 -144.26,-113.8 -302.45,-25.38 -374.58,29.14 -15.66,12.25 -10.43,38.59 8.66,41.42 42.65,8.43 106.04,26.32 164.27,62.98 -151.19,16.92 -265.99,140.12 -319.77,209.71 -12.19,16.01 0.82,39.51 19.06,35.75z" />
                <path {...fil0} d="M1331.03 2531.91c37.38,-0.88 143.42,-2.79 205.99,-3.79 6.08,27.31 10.4,74.29 12.98,127.02l-218.97 -123.23zm-497.13 -460.76c46.07,60.13 127.78,80.85 180.75,87.45 24.43,84.63 89.56,308.41 116.54,379.89 14.74,41.39 49.47,81.87 112.04,110.98l489.28 275.51c10.5,5.7 20.9,8.49 31.33,8.49 25.14,0 49.54,-15.07 60.79,-40.39 18.27,-35.78 5.26,-80.93 -28.65,-99.78l-106.85 -60.11c0.82,-173.96 -18.27,-283.12 -55.65,-324.48 -17.4,-18.83 -39.15,-29.11 -61.73,-29.11 -40.8,0 -150.34,1.88 -212.89,2.79 -21.74,-83.72 -52.15,-179.63 -67.81,-226.58 52.15,-9.48 120.86,-31.1 161.69,-84.66 26.91,-34.86 37.3,-77.14 29.56,-123.2 -15.67,-89.33 -99.14,-126.99 -161.69,-141.06 1.76,13.16 3.5,26.32 3.5,40.39 0,31.07 -7,60.19 -19.16,86.51 32.17,8.48 58.23,23.53 60.91,37.69 1.63,9.37 0,14.04 -3.53,18.74 -20.9,26.32 -89.53,45.15 -139.92,45.15l-158.19 0c-50.39,0 -119.04,-18.83 -139.94,-45.15 -3.5,-4.7 -5.16,-9.37 -4.34,-18.74 2.61,-14.16 28.67,-29.2 60.91,-37.69 -12.26,-26.32 -19.16,-55.44 -19.16,-86.51 0,-14.07 1.74,-27.24 3.5,-40.39 -62.65,15.07 -145.18,51.73 -160.85,141.06 -7.74,46.06 2.66,88.34 29.56,123.2z" />
                <path {...fil0} d="M1005.4 1847.17c0,-82.28 61.73,-149.08 137.8,-149.08 76.12,0 137.75,66.8 137.75,149.08 0,82.39 -61.63,149.08 -137.75,149.08 -76.06,0 -137.8,-66.69 -137.8,-149.08z" />
                <g id="_2006457882768">
                    <path {...fil2} d="M1464.64 294.54c-130.11,0 -235.57,105.49 -235.57,235.61 0,130.11 105.46,235.61 235.57,235.61 130.09,0 235.55,-105.5 235.55,-235.61 0,-130.12 -105.46,-235.61 -235.55,-235.61z" />
                    <path {...fil2} d="M1464.64 220.72c31.27,0 56.75,-25.39 56.75,-56.75l0 -93.76c0,-31.33 -25.48,-56.72 -56.75,-56.72 -31.3,0 -56.78,25.39 -56.78,56.72l0 93.76c0,31.36 25.48,56.75 56.78,56.75z" />
                    <path {...fil2} d="M1165.64 311.35c11.02,11.13 25.56,16.67 40.08,16.67 14.57,0 29.08,-5.54 40.19,-16.67 22.15,-22.13 22.15,-58.11 0,-80.24l-66.29 -66.27c-22.21,-22.19 -58.14,-22.19 -80.27,0 -22.13,22.15 -22.13,58.11 0,80.24l66.29 66.27z" />
                    <path {...fil2} d="M1155.19 530.15c0,-31.33 -25.39,-56.78 -56.7,-56.78l-93.79 0c-31.3,0 -56.75,25.45 -56.75,56.78 0,31.33 25.45,56.72 56.75,56.72l93.79 0c31.3,0 56.7,-25.39 56.7,-56.72z" />
                    <path {...fil2} d="M1165.64 748.94l-66.29 66.26c-22.13,22.13 -22.13,58 0,80.16 11.02,11.16 25.56,16.7 40.07,16.7 14.54,0 29,-5.54 40.11,-16.62l66.3 -66.21c22.13,-22.19 22.24,-58.06 0.08,-80.21 -22.21,-22.27 -58.03,-22.21 -80.27,-0.08z" />
                    <path {...fil2} d="M1464.64 839.58c-31.3,0 -56.78,25.42 -56.78,56.75l0 93.7c0,31.33 25.48,56.78 56.78,56.78 31.27,0 56.75,-25.45 56.75,-56.78l0 -93.7c0,-31.33 -25.48,-56.75 -56.75,-56.75z" />
                    <path {...fil2} d="M1763.61 748.94c-22.21,-22.13 -58.06,-22.07 -80.27,0.08 -22.15,22.16 -22.04,58.03 0.09,80.21l66.29 66.21c11.11,11.08 25.56,16.62 40.11,16.62 14.54,0 29.06,-5.59 40.07,-16.7 22.24,-22.16 22.16,-58.03 0,-80.16l-66.29 -66.26z" />
                    <path {...fil2} d="M1924.54 473.37l-93.79 0c-31.3,0 -56.67,25.45 -56.67,56.78 0,31.33 25.36,56.72 56.67,56.72l93.79 0c31.3,0 56.75,-25.39 56.75,-56.72 0,-31.33 -25.45,-56.78 -56.75,-56.78z" />
                    <path {...fil2} d="M1723.54 328.03c14.51,0 29.06,-5.54 40.07,-16.67l66.29 -66.27c22.24,-22.13 22.24,-58.09 0,-80.24 -22.13,-22.19 -58.06,-22.19 -80.26,0l-66.3 66.27c-22.15,22.13 -22.15,58.11 0,80.24 11.11,11.13 25.65,16.67 40.19,16.67z" />
                </g>
            </g>
        </SVGFactory>

    )
}


export default RelaxFF