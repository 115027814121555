import enPages from "./enPages"
import enComponents from "./enComponents"
import enDictionary from "./enDictionary"
import enMainFooter from "./enMainFooter"

export const enGlobal = {
    page: { ...enPages },
    component: { ...enComponents },
    dictionary: { ...enDictionary },
    footer: { ...enMainFooter }

}