import {  LOGIN_FORM_GOOGLE_BUTTON, LOGIN_FORM_SUBMIT_BUTTON, ONLINE_BOOKING_DESCRIPTION, ONLINE_BOOKING_HEADER, PAYMENT_DESCRIPTION, PAYMENT_HEADER, SMS_EMAIL_NOTIFICATIONS_DESCRIPTION, SMS_EMAIL_NOTIFICATIONS_HEADER, TEMPLATES_BUTTON, WEBSITE_TEMPLATES_DESCRIPTION, WEBSITE_TEMPLATES_HEADER, WELCOME_BUSINESS_DESCRIPTION, WELCOME_BUSINESS_HEADER } from "../types";
import { LOGIN_FORM_TITLE, LOGIN_FORM_ISSUES_OPTION, LOGIN_FORM_ISSUES_QUESTION, LOGIN_FORM_LABEL_EMAIL, LOGIN_FORM_LABEL_PASSWORD, LOGIN_FORM_NOT_REGISTERED_OPTION, LOGIN_FORM_NOT_REGISTERED_QUESTION, LOGIN_FORM_PLACEHOLDER_EMAIL, LOGIN_FORM_PLACEHOLDER_PASSWORD, LOGIN_FORM_REMEBER_ME_LABEL } from '../types'
import CR_page from "./enCreditRepairPage";
import about_us_page from "./enAboutUsPage";
import en_home_page from "./enHomePage";

const enPages = {

    home: { ...en_home_page },//Home Page
    credit_repair: { ...CR_page },//  Credit Repair Page
    about_us: { ...about_us_page },//  About Us Page

    login: {//Login  and Registration Pages

        form: {

            [LOGIN_FORM_TITLE]: 'Log in to your account',
            [LOGIN_FORM_NOT_REGISTERED_QUESTION]: "Don't have an account?",
            [LOGIN_FORM_NOT_REGISTERED_OPTION]: 'Register here!',
            [LOGIN_FORM_ISSUES_QUESTION]: "Are you experiencing any problems with the authentication process?",
            [LOGIN_FORM_ISSUES_OPTION]: 'Contact us!',
            [LOGIN_FORM_REMEBER_ME_LABEL]: 'Remember me!',
            [LOGIN_FORM_LABEL_EMAIL]: 'Email',
            [LOGIN_FORM_PLACEHOLDER_EMAIL]: 'Enter your email address...',
            [LOGIN_FORM_LABEL_PASSWORD]: 'Password',
            [LOGIN_FORM_PLACEHOLDER_PASSWORD]: 'Enter your password...',
            [LOGIN_FORM_SUBMIT_BUTTON]: 'Login',
            [LOGIN_FORM_GOOGLE_BUTTON]: 'Continue with Google',
        },

        /* errorMessages: {
             [WRONG_CREDENTIALS]: WRONG_USERNAME_OR_PASSWORD,
             [EMPTY_FIELD]: FIELD_IS_REQUIRED,
             [NO_MATCH_PASSWORDS]: PASSWORDS_DO_NOT_MATCH,
             [USERNAME_ALREADY_EXISTS]: THIS_USERNAME_ALREADYS_EXISTS,
             [EMAIL_ALREADY_USED]: EMAIL_ALREADY_REGISTERED,
         }*/
    }


}


export default enPages;