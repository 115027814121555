import {
    Box,
    Button,
    ButtonGroup,
    Center,
    CloseButton,
    Flex,
    Icon,
    Stack,
    Text,
} from '@chakra-ui/react'
import { useState } from 'react'

import styles from './NotificationWithLink.module.css'
export const NotificationWithLink = ({ onClose = () => { }, title, message, buttons, iconButton }) => {

    const [animation, setAnimation] = useState('open')


    const hide = async (ms) => {
        setAnimation('close')
        await new Promise(r => setTimeout(r, ms))
        onClose();
    }



    return (<Box
        className={animation === 'open' ? `${styles['notification_open']}` : `${styles['notification_close']} ${styles['closed']}`}
        pt={{
            base: '4',
            md: '8',
        }}
        pb={{
            base: '12',
            md: '24',
        }}
        px={{
            base: '4',
            md: '8',
        }}

    >
        <Flex direction="row-reverse">
            <Flex
                direction={'row'}
                width={{
                    base: 'full',
                    sm: 'md',
                }}
                boxShadow="md"
                bg="bg.surface"
                borderRadius="lg"
                overflow="hidden"
            >
                <Center
                    display={'flex'}
                    //bg="bg.accent.default"
                    px="5"
                >
                    {iconButton}
                </Center>
                <Stack direction="row" p="4" spacing="3" flex="1">
                    <Stack spacing="2.5" flex="1">
                        <Stack spacing="1">
                            <Text textStyle="lg" fontWeight="medium">
                                {title}
                            </Text>
                            {message}
                        </Stack>
                        <ButtonGroup variant="text" size="sm" spacing="3">
                            {buttons?.map((button, index) => (
                                <Button {...button?.props} key={index}>
                                    {button?.text || 'Go'}
                                </Button>)
                            )}
                        </ButtonGroup>
                    </Stack>
                    <CloseButton transform="translateY(-6px)" onClick={() => hide(500)} />
                </Stack>
            </Flex>
        </Flex>
    </Box>
    )
}


