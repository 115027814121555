import esPages from "./esPages"
import esComponents from "./esComponents"
import esDictionary from "./esDictionary"
import esMainFooter from "./esMainFooter"

export const esGlobal = {
    page: { ...esPages },
    component: { ...esComponents },
    dictionary:{...esDictionary},
    footer: { ...esMainFooter }
}