import { MF_PAGE_LINK_ABOUT_US, MF_PAGE_LINK_CONTACT, MF_PAGE_LINK_COOKIES_POLICY, MF_PAGE_LINK_DISCLAIMER, MF_PAGE_LINK_PRIVACY_POLICY, MF_PAGE_LINK_TERMS_OF_USE, MF_PAGE_LINK_TITLE_LINARES, MF_PAGE_LINK_TITLE_SERVICES } from "../types";



const enMainFooter = {
    [MF_PAGE_LINK_TITLE_LINARES]: "Linares",
    [MF_PAGE_LINK_TITLE_SERVICES]: "Services",
    [MF_PAGE_LINK_ABOUT_US]: "About Us",
    [MF_PAGE_LINK_CONTACT]: "Contact Us",
    [MF_PAGE_LINK_TERMS_OF_USE]: "Terms and Conditions",
    [MF_PAGE_LINK_PRIVACY_POLICY]: "Privacy Policy",
    [MF_PAGE_LINK_DISCLAIMER]: "Disclaimer",
    [MF_PAGE_LINK_COOKIES_POLICY]: "Cookies Policy",
}


export default enMainFooter;
