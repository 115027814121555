


const enDictionary = {
    month: "month",

}


export default enDictionary;

