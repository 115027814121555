import { Box } from "@chakra-ui/react"
import { Travel } from "../components/travel/Travel"


export const TravelPage = () => {


    return (<Box>
       
        <Travel />
    </Box>)

}