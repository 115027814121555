import { Avatar, Box, Container, Stack, Text } from '@chakra-ui/react'
import { Trans } from 'react-i18next'
import { CR_PRICING_TESTIMONIAL } from '../../locales/types'
import { Rating } from '../rating/Raiting'

const transPath= "page.credit_repair.section.pricing"
export const CreditRepairTestimonials = () => (
  <Box
    as="section"
    py={{
      base: '16',
      md: '24',
    }}
  >
    <Container>
      <Stack spacing="8" align="center" textAlign="center">
      <Rating/>
        <Text
          textStyle={{
            base: '3xl',
            md: '4xl',
          }}
          fontWeight="medium"
        >
            <Trans i18nKey={`${transPath}.${CR_PRICING_TESTIMONIAL}`} />
        </Text>
        <Stack spacing="4" align="center">
          <Avatar
            src="https://i.pravatar.cc/150?u=a042581f5e29089704d"
            size="lg"
            name="CoroBiz"
          />
          <Stack spacing="1">
            <Text fontWeight="semibold" textStyle="lg">
              Luis C.
            </Text>
            <Text color="fg.muted">06/08/2023</Text>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  </Box>
)


