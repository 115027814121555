import { useColorMode } from "@chakra-ui/react"
import SVGFactory from "./SVGFactory"


const CreditScoreFF = ({
    size = 52, // or any default size of your choice
    color,
    presetColor = true // or any color of your choice

}) => {
    const { colorMode } = useColorMode()
    const adaptativeColor = color ? color : colorMode === 'dark' ? 'white' : 'black'


    const fil0 = { fill: presetColor ? '#3EAD3E' : adaptativeColor, fillRule: 'nonzero' }
    const fil1 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil2 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil3 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil4 = { fill: presetColor ? '#389D38' : adaptativeColor, fillRule: 'nonzero' }
    const fil5 = { fill: presetColor ? '#67C735' : adaptativeColor, fillRule: 'nonzero' }
    const fil6 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil7 = { fill: presetColor ? '#2EA1DA' : adaptativeColor, fillRule: 'nonzero' }
    const fil8 = { fill: presetColor ? '#F2CE1B' : adaptativeColor, fillRule: 'nonzero' }
    const fil9 = { fill: presetColor ? '#F2CE1B' : adaptativeColor, fillRule: 'nonzero' }
    const fil10 = { fill: presetColor ? '#349234' : adaptativeColor, fillRule: 'nonzero' }
    const fil11 = { fill: presetColor ? '#C83636' : adaptativeColor, fillRule: 'nonzero' }
    const fil12 = { fill: presetColor ? '#C3C3C3' : adaptativeColor, fillRule: 'nonzero' }
    const fil13 = { fill: presetColor ? '#2F842F' : adaptativeColor, fillRule: 'nonzero' }
    const fil14 = { fill: presetColor ? '#B53131' : adaptativeColor, fillRule: 'nonzero' }

    return (
        <SVGFactory >
            <g >
                <path {...fil0} d="M2558.28 2091.97l-454.46 -1.5c0,-7.98 -0.1,-15.93 -0.44,-23.82 -5.52,-175.93 -78.81,-335.02 -194.75,-451.7l322.36 -320.41c197.96,199.23 321.84,472.05 327.13,773.62 0.17,7.94 0.17,15.88 0.17,23.82z" />
                <path {...fil1} d="M2230.98 1294.53l-322.36 320.41 -0.05 0c-121.22,-121.95 -288.86,-197.67 -474.26,-198.3l1.47 -454.56c311,1 592.07,127.99 795.2,332.44z" />
                <path {...fil2} d="M958.8 1611.85c-116.7,115.95 -191.04,274.56 -197.68,450.49 -0.39,7.85 -0.54,15.79 -0.57,23.82l-454.53 -1.5c0.02,-7.94 0.11,-15.88 0.32,-23.82 7.24,-301.48 132.88,-573.52 332.09,-771.44l320.38 322.45z" />
                <path  {...fil3} d="M1435.78 962.09l-1.47 454.56c-185.48,-0.59 -353.6,74.07 -475.51,195.2l-320.38 -322.45c204.44,-203.15 486.37,-328.31 797.36,-327.31z" />
                <path {...fil4} d="M2225.8 2067.03l-122.43 -0.39 0 0c-5.52,-175.93 -78.81,-335.02 -194.75,-451.7l67.04 -66.61 0 0c141.11,131.93 234.16,314.54 250.14,518.7z" />
                <path {...fil4} d="M1908.62 1614.94l0 0 67 -66.61c0.05,0 0.05,0 0.05,0l-67.04 66.61z" />
                <path {...fil5} d="M1908.62 1614.94l-0.05 0c-121.22,-121.95 -288.86,-197.67 -474.26,-198.3l0.27 -82.74 0.06 0c209.22,0.67 399.38,82.01 541.03,214.43l-67.04 66.61z" />
                <path {...fil6} d="M761.11 2062.33l-122.43 -0.38c17.3,-204.07 111.54,-386.11 253.5,-517.11l0 0 66.6 67.01c-116.7,115.95 -191.04,274.56 -197.68,450.49l0 0z" />
                <path {...fil7} d="M958.8 1611.85l0 0 -66.6 -67.01c72.93,-67.24 158.43,-121.08 252.57,-157.54l20.23 84.57c-77.39,33.6 -147.2,81.33 -206.2,139.97z" />
                <polygon {...fil5} points="1434.31,1416.64 1434.31,1416.64 1434.57,1333.9 1434.57,1333.9 " />
                <path {...fil7} d="M1165 1471.89l-20.24 -84.59c83.27,-29.53 193.81,-53.4 287.19,-53.4 0.87,0 1.74,0 2.62,0l-0.27 82.74c-0.74,0 -1.48,0 -2.22,0 -75.33,0 -199.46,32.39 -267.08,55.24z" />
                <path {...fil8} d="M1614.18 1969.56c-41.65,18.02 -91.42,17.86 -135.53,-6.74 -89.56,-50.02 -105.74,-172.09 -32.26,-243.7l500.8 -596.36 -244.33 739.46c-11.3,50.78 -45.76,88.72 -88.69,107.35z" />
                <path {...fil9} d="M1610.91 1741.37l333.66 -612.81 -232.3 705.15c-30.01,-34.24 -65.74,-66.46 -101.36,-92.34z" />
                <path {...fil8} d="M1810.69 2089.55l-757.02 -2.47c0.02,-7.99 0.3,-15.93 0.88,-23.82 8.23,-126.46 78.49,-235.87 180.61,-298.28 48.67,-29.73 104.57,-48.7 164.39,-53.79 11.17,-1.02 22.46,-1.5 33.87,-1.45 201,0.63 364.97,157.97 376.54,355.99 0.54,7.84 0.73,15.78 0.73,23.82z" />
                <path {...fil10} d="M2558.28 2091.97l-330.97 -1.06c-0.39,-7.99 -0.87,-15.93 -1.5,-23.87l332.3 1.11 0 0c0.11,7.55 0.17,15.16 0.17,22.76l0 1.01 0 0.05z" />
                <path {...fil11} d="M760.54 2086.16l-454.53 -1.5c0.02,-7.94 0.11,-15.88 0.32,-23.82l0 0 332.36 1.11c-0.67,7.89 -1.23,15.83 -1.66,23.77l123.51 0.43z" />
                <polygon {...fil12} points="2227.3,2090.91 2103.81,2090.46 2103.81,2090.46 2227.3,2090.91 " />
                <path {...fil13} d="M2227.3 2090.91l-123.49 -0.44c0,-0.72 0,-1.5 0,-2.23l0 0c0,-7.26 -0.1,-14.42 -0.44,-21.59l122.43 0.39c0.63,7.94 1.11,15.88 1.5,23.87z" />
                <path {...fil14} d="M760.54 2086.16l0 0 -123.51 -0.43c0.43,-7.94 1,-15.88 1.66,-23.77l122.43 0.38c-0.39,7.85 -0.54,15.79 -0.57,23.82z" />
                <path {...fil9} d="M1810.69 2089.55l-22.27 -0.1c0.05,-7.99 -0.19,-15.93 -0.73,-23.81l22.27 0.09c0.48,7.6 0.73,15.3 0.73,23.04 0,0.24 0,0.54 0,0.78zm-734.76 -2.37l-22.26 -0.1c0.02,-7.99 0.3,-15.93 0.88,-23.82l22.24 0.09c-0.55,7.89 -0.84,15.78 -0.86,23.82z" />
                <path {...fil9} d="M1788.42 2089.45l-712.49 -2.27 0 0c0.02,-8.04 0.31,-15.93 0.86,-23.82l710.91 2.28c0.54,7.89 0.78,15.83 0.73,23.81l0 0z" />
            </g>
        </SVGFactory>

    )
}


export default CreditScoreFF