import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  Box,
  HStack,
  Text,
  Icon,
  Link,

} from '@chakra-ui/react'

import { PopoverIcon } from './PopoverIcon'
import { NAVBAR_LINK_SERVICES } from '../../../locales/types'
import { Trans } from 'react-i18next'
import { services } from './NavbarNavigateOptions'
import { NavbarButtonLink } from './NavbarButtonLink'

export const ServicesPopover = ({ afterSelect, ...props }) => {

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  return (<Box >
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}
      trigger="hover"
      openDelay={0} >
      <PopoverTrigger >
        <Button as={Link}  variant={'unstyled'}  color={'white'} m={0} p={0} rightIcon={<PopoverIcon isOpen={isOpen} />} onClick={onToggle} {...props}>
          <Trans i18nKey={`component.navbar.${NAVBAR_LINK_SERVICES}`} />
        </Button>
      </PopoverTrigger>
      <PopoverContent p="2" mt={10} shadow="xl"
        border={'none'}
        bg={'blackAlpha.900'} rounded="lg"
      >

        <Stack spacing="4" w={'full'} px="4">
          {services.map((item) => (

            <Stack key={item.title} direction="row" justify="space-between" spacing="4">
              <HStack spacing="3">
                <Icon w={8} h={8} mr="4" fontSize="24"
                  _groupHover={{
                    color: 'white',
                  }}
                  as={item.icon}
                />
                <NavbarButtonLink afterAction={() => {
                  !!afterSelect && afterSelect();
                  onClose()
                }} color={'white'} item={item} fontSize="2xl" />
                <Text color="fg.muted">{item.handle}</Text>

              </HStack>
              <Text color="fg.muted">{item.lastSeen}</Text>
            </Stack>
          ))}
        </Stack>
      </PopoverContent>
    </Popover>
  </Box>
  )
}




