import { Box, Button, Container, Heading, Image, Stack, Text } from '@chakra-ui/react'

export const HStackCardWithImage = ({ title, description, buttons, img, index=0 }) => (
  <Container>
    <Stack
      direction={{
        base: 'column',
        md: index % 2 === 0 ?'row' : 'row-reverse',
      }}
     
      spacing={{
        base: '5%',
        lg: '15%',
      }}
    >
      <Stack
        spacing={{
          base: '8',
          md: '10',
        }}
        width="full"
        justify="center"
      >
        <Stack
          spacing={{
            base: '4',
            md: '6',
          }}
        >
          <Heading
            size={{
              base: 'sm',
              md: 'lg',
            }}
          >
            {title}
          </Heading>
          <Text
            fontSize={{
              base: 'lg',
              md: 'xl',
            }}
            color="fg.muted"
          >
            {description}
          </Text>
        </Stack>
        <Stack
          direction={{
            base: 'column-reverse',
            md: 'row',
          }}
          spacing="3"
        >
          {buttons}
        </Stack>
      </Stack>
      <Image
        width="full"
        height={{
          base: 'auto',
          md: 'lg',
        }}
        alt={title}
        objectFit="cover"
        src={img}
      />
    </Stack>
  </Container>

)
