import { Box, Divider, HStack, Icon, Stack, Text, Tooltip } from '@chakra-ui/react'
import { ImCheckmark } from 'react-icons/im'

export const PromptSuggestButton = (props) => {
  const { id, icon, title, description,isSelected=false, onClick = () => { } } = props
  return (
    <Tooltip label="Click to select" rounded="md" placement="top">
      <Stack
        data-group
        as="button"
        bg="bg.surface"
        borderWidth="1px"
        rounded="md"
        spacing="0"
        px="4"
        py="2"
        type="button"
        fontSize="sm"
        onClick={() => onClick(id)}
      >
        <Stack direction={{base:'column',xl:'row'}}>
          {isSelected&&<Icon  as={ImCheckmark} color={'accent'}/>}
          <Box>{icon}</Box>
          <Text
            align="start"
            fontWeight="small"

            _groupHover={{
              color: 'accent',
            }}
          >
            {title}
          </Text>
        </Stack>
        <Divider/>
        <Text margin={'auto'} justifySelf={'end'}   fontSize={'sm'} align="start" color="fg.muted">
          {description}
        </Text>
      </Stack>
    </Tooltip>
  )
}
