import { ABOUT_US_FIRST_PARAGRAPH, ABOUT_US_HEADER_TITLE, ABOUT_US_HELMET_DESCRIPTION, ABOUT_US_HELMET_TITLE, ABOUT_US_INTRO_DESCRIPTION, ABOUT_US_INTRO_SUBTITLE, ABOUT_US_INTRO_TITLE, ABOUT_US_SECOND_PARAGRAPH, ABOUT_US_THIRD_PARAGRAPH } from "../types";


const es_about_us_page = {//ABOUT US PAGE
    helmet: {
        [ABOUT_US_HELMET_TITLE]: "Sobre Nosotros | Linares Professional Family Services Corp",
        [ABOUT_US_HELMET_DESCRIPTION]: `¡Bienvenido a nuestra agencia de servicios familiares ubicada en Miami, Florida!
         Somos un equipo de profesionales dedicados que están comprometidos a brindar atención y apoyo de la más alta calidad a las familias necesitadas.`,
    },

  
 
        [ABOUT_US_HEADER_TITLE]: '¡Bienvenido a nuestra agencia de servicios familiares ubicada en Miami, Florida!',
        [ABOUT_US_INTRO_TITLE]: 'LINARES PROFESSIONAL FAMILY SERVICE CORP',
        [ABOUT_US_INTRO_SUBTITLE]: 'Una agencia profesional de servicios familiares',
        [ABOUT_US_INTRO_DESCRIPTION]: 'Somos un equipo de profesionales dedicados que están comprometidos a brindar atención y apoyo de la más alta calidad a las familias necesitadas.',
        [ABOUT_US_FIRST_PARAGRAPH]: `Nuestra agencia ofrece una amplia gama de servicios para ayudar a las familias a navegar por los desafíos de la vida. 
        Ofrecemos servicios de preparación de impuestos para ayudarlo a presentar sus impuestos de manera precisa y eficiente. 
        Nuestros servicios de inmigración pueden ayudarlo con solicitudes de visa, peticiones de residencia permanente, solicitudes de naturalización entre otros. 
        También ofrecemos paquetes de viajes de vacaciones para ayudarlo a planificar la escapada perfecta.`,
        [ABOUT_US_SECOND_PARAGRAPH]: `Además de estos servicios, ofrecemos asistencia en la solicitud de pasaportes para ayudarle a obtener su pasaporte de forma rápida y sencilla. 
        Nuestro equipo también puede ayudarlo a solicitar beneficios de seguro social, cupones de alimentos, asistencia en efectivo y Medicaid. 
        Entendemos que estos servicios pueden ser abrumadores y complejos, por lo que estamos aquí para guiarlo a través del proceso.`,
        [ABOUT_US_THIRD_PARAGRAPH]: `También ofrecemos asistencia para la solicitud de desempleo y servicios de cobertura de atención médica. 
        Nuestro equipo puede ayudarlo a través de el proceso de solicitud de desempleo y brindarle orientación sobre cómo acceder a la cobertura de atención médica.`,
    



}

export default es_about_us_page;

