import { AspectRatio, Box, Image, Link, Skeleton, Text } from '@chakra-ui/react'
import { motion } from "framer-motion"
export const CategoryCard = (props) => {
  const { category, ...rootProps } = props
  return (

    <Box position="relative"  maxW={'xl'} key={category.name} borderRadius="xl" overflow="hidden" {...rootProps} >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
      
          <AspectRatio ratio={6 / 4}>
            <Image src={category.imageUrl} alt={category.name} fallback={<Skeleton />} />
          </AspectRatio>
          <Box

            transition={'2s ease-in-out .5s'}
            _hover={{ bgGradient: "linear(to-b, transparent 65%, gray.700)" }}
            position="absolute"
            inset="0"
            bgGradient="linear(to-b, transparent 40%, gray.900)"
            boxSize="full"
          />
          <Box position="absolute" bottom="7" width="full" textAlign="center">
            <Text color="white" fontSize="xl" fontWeight="bold" textTransform={'uppercase'}>
              {category.name}
            </Text>
          </Box>
       
      </motion.div>
      
    </Box>)
  
}
