'use client'

import {
  Box,
  Heading,
  Stack,

  Center,

} from '@chakra-ui/react'


import { motion } from 'framer-motion'
import imgStageOne from '../../assets/images/credit_repair/register.svg'
import imgStageTwo from '../../assets/images/credit_repair/coach.svg'
import imgStageThree from '../../assets/images/credit_repair/research.svg'
import imgStagefour from '../../assets/images/credit_repair/disputes.svg'
import { HStackCardWithImage } from '../cards/HStackCardWithImage'

import { Trans } from 'react-i18next'
import {
  CR_PROCESS_ASSESTMENT_DESCRIPTION,
  CR_PROCESS_ASSESTMENT_HEADER,
  CR_PROCESS_COACH_DESCRIPTION,
  CR_PROCESS_COACH_HEADER,
  CR_PROCESS_DISPUTES_DESCRIPTION,
  CR_PROCESS_DISPUTES_HEADER,
  CR_PROCESS_HEADER,
  CR_PROCESS_SIGUNP_DESCRIPTION,
  CR_PROCESS_SIGUNP_HEADER
} from '../../locales/types'
const transPath = "page.credit_repair.section.process"
export default function CredirRepairStageDetails() {
  return (
    <Box pos={'relative'} as='section' id='repair-process-container' >
    
        <Center py={'8em'} >
          <Stack>
            <Stack spacing="3">
              <Heading
                size={{
                  base: 'xl',
                  md: '2xl',
                }}
              >
               <Trans i18nKey={`${transPath}.${CR_PROCESS_HEADER}`} />
              </Heading>
              
            </Stack>
           

          </Stack>
        </Center>
  
      <Box >
        {
          stagesListings.map((stage, index) => {
            const { id, title, image, description, link } = stage;
            return (<motion.div
              key={id}
              initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                x: index % 2 === 0 ? 50 : -50
              }}
              whileInView={{
                opacity: 1,
                x: 0, // Slide in to its original position
                transition: {

                  duration: 2 // Animation duration
                }
              }}
              viewport={{ once: true }}
            >
              <Box m="5%" w="90%">
                <HStackCardWithImage
                  title={<Trans i18nKey={`${transPath}.${title}`} />}
                  index={index}
                  img={image}
                  description={<Trans i18nKey={`${transPath}.${description}`} />}
                  link={link} />
              </Box>
            </motion.div>);
          }
          )
        }
     
      </Box>
    </Box >
  )
}



const stagesListings = [
  {
    id: 'step001',
    title: CR_PROCESS_SIGUNP_HEADER,
    step: '1',
    description: CR_PROCESS_SIGUNP_DESCRIPTION,
    image: imgStageOne,
    action: {
      title: 'Registrarme',
      onClick: () => alert('Clicked!')
    },
  },
  {
    id: 'step002',
    title: CR_PROCESS_COACH_HEADER,
    step: '2',
    description: CR_PROCESS_COACH_DESCRIPTION,
    image: imgStageTwo,
  },
  {
    id: 'step003',
    title: CR_PROCESS_ASSESTMENT_HEADER,
    step: '3',
    description: CR_PROCESS_ASSESTMENT_DESCRIPTION,
    image: imgStageThree,
  },


  {
    id: 'step004',
    title: CR_PROCESS_DISPUTES_HEADER,
    step: '4',
    description: CR_PROCESS_DISPUTES_DESCRIPTION,
    image: imgStagefour,

  },

]