'use client'

import {
    Badge,
    Box,
    Button,
    Center,
    Flex,
    Heading,
    IconButton,
    Image,
    Stack,
    Text,
    VStack,
    useColorModeValue,
} from '@chakra-ui/react'

import { useState } from 'react'
import { StarIcon } from '@chakra-ui/icons'
import { BiBookmarkHeart, BiHeart } from 'react-icons/bi'
import { motion } from 'framer-motion'





export const TravelOptionSection = ({ title = 'Title', cities = '', options, description, mainImage, images, prevPrice, currentPrice, reviewCount = 90, rating = 4 }) => {
    const [isFavorite, setIsFavorite] = useState(false)
    return (

        <Center cursor={'pointer'} py={6} _hover={{ '.preview-images': { display: ['none', null, null, null, 'flex'] } }}>

            <Stack
                borderWidth="1px"
                borderRadius="lg"
                rounded={'2xl'}
                w={'100%'}
                direction={{ base: 'column', xl: 'row' }}
                // eslint-disable-next-line react-hooks/rules-of-hooks
                bg={useColorModeValue('white', 'gray.900')}
                boxShadow={'2xl'}
            >

                <Flex flex={1} >
                    
                    <Image
                        loading='lazy'
                        borderLeftRadius={'2xl'}
                        objectFit="fill"
                        boxSize="100%"
                        src={mainImage}
                        alt={title}
                    />

                </Flex>

                <Stack
                    padding={4}
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}>
                    <Heading fontSize={'2xl'} fontFamily={'body'}>
                        {title}
                    </Heading>
                    <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
                        {cities}
                    </Text>
                    <Text
                        fontSize={'lg'}
                        fontFamily={'monospace'}
                        textAlign={'center'}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        color={useColorModeValue('gray.700', 'gray.400')}
                        px={3}>
                        {options}

                    </Text>
                    <Text
                        textAlign={'center'}
                        noOfLines={3}
                        color={useColorModeValue('gray.700', 'gray.400')}
                        px={3}>
                        {description}

                    </Text>

                    {(!!prevPrice || !!currentPrice) && <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>

                        {!!prevPrice && <Text

                            fontWeight={300}
                            fontSize={'xl'}>
                            <del>{prevPrice}</del>
                        </Text>}
                        {!!currentPrice && <Text
                            ml={15}

                            fontWeight={300}
                            color={'green'}
                            fontFamily={'monospace'}
                            fontSize={'3xl'}>
                            {currentPrice}

                        </Text>}
                        <small>USD</small>
                    </Stack>}
                    <Box>
                        <Button
                            flex={1}
                            fontSize={'sm'}
                            rounded={'full'}
                            bg={'blue.400'}
                            color={'black'}
                            boxShadow={
                                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
                            }
                            _hover={{
                                bg: 'green.500',
                                color: 'white'
                            }}
                            _focus={{
                                bg: 'blue.500',
                            }}>
                            Reservar
                        </Button>
                    </Box>

                    <Box display={'flex'} justifyContent={'flex-end'} p={5}>
                        <Stack

                            spacing={5}
                            mt={'2rem'}
                            direction={'row'}
                            padding={2}
                            justifyContent={'space-between'}
                            alignItems={'center'}>
                            <Box display='flex' mt='2' alignItems='center'>
                                {Array(5)
                                    .fill('')
                                    .map((_, i) => (
                                        <StarIcon
                                            key={i}
                                            color={i < rating ? 'yellow.400' : 'gray.300'}
                                        />
                                    ))}
                                <Box as='span' ml='2' color='gray.600' fontSize='sm'>
                                    {reviewCount} reviews
                                </Box>
                            </Box>


                        </Stack>
                    </Box>
                </Stack>
                <IconButton onClick={() => setIsFavorite(!isFavorite)} variant={'ghost'} icon={isFavorite ? <BiBookmarkHeart color='green' size={40} /> : <BiHeart size={25} />} />
            </Stack>

        </Center>

    )
}


