import {

  Box,
  ButtonGroup,
  Container,
  HStack,
  Icon,
  IconButton,
  Image,
  useColorMode,
} from '@chakra-ui/react'
import { FiBell, FiSearch } from 'react-icons/fi'

import logo from '@assets/logo512.png'
import { MobileDrawer } from './MobileDrawer'
import { NavbarButtonLink } from './NavbarButtonLink'
import { navbarButtons, navbarRightLinks } from './NavbarNavigateOptions'
import LanguageMenu from '../../menu/LanguageMenu'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import { ServicesPopover } from './ServicesPopover'

export const MainNabar = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  return (<Box as="section" pos={'sticky'} top={0} zIndex={99999}>
    <Box
      bg="bg.accent.default"
      position="relative"
     >
      <Container py="5">
        <HStack justify="space-between" spacing="8">
          <HStack spacing="10">
            <HStack spacing="3">
              <MobileDrawer />
              <Image
                boxSize='60px'
                objectFit='cover'
                src={logo}
                alt='Linares Logo'
              />
            </HStack>
            <ButtonGroup
              size="lg"
              variant="text.accent"
              colorScheme="gray"
              spacing="8"
              display={{
                base: 'none',
                lg: 'flex',
              }}
            >
              {navbarButtons.map((item) => {
                return (<NavbarButtonLink key={item?.title} item={item} />)
              }
              )}
              <ServicesPopover />
              {navbarRightLinks.map((item) => {
                return (<NavbarButtonLink key={item?.title} item={item} />)
              }
              )}


            </ButtonGroup>
          </HStack>
          <HStack
            spacing={{
              base: '2',
              md: '4',
            }}
          >

            <ButtonGroup variant="tertiary.accent" spacing="1">
              <IconButton icon={<FiBell />} aria-label="Notifications" isRound />
              <IconButton icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />} onClick={toggleColorMode} />
              <LanguageMenu variant='solid' />
            </ButtonGroup>
          </HStack>
        </HStack>
      </Container>
    </Box>
  </Box>
  )
}
