import { Box, Heading } from "@chakra-ui/layout"
import { HeroWithCropedImage } from "@components/heroes/HeroWithCropedImage"
import { Button, Container, FormControl, FormLabel,  Select, } from '@chakra-ui/react'
import { FiSun } from 'react-icons/fi'

const title = (<Heading
    size={{
        base: 'md',
        md: 'xl',

    }}
    
>
    We are here for you!
</Heading>)

const helpOptions = [
    {
        title: 'Getting started',
        url: '#',
        icon: FiSun
    },
    {
        title: 'Calendar & Booking',
        url: '#'
    },
    {
        title: 'Paymenst',
        url: '#'
    },
    {
        title: 'Subscriptions',
        url: '#'
    }
]

const formOptions = (<Box
    as="section"

    pt={{
        base: '4',
        md: '8',
    }}
    pb="40"
>
    <Container maxW="lg">
        <FormControl id="colormode">
            <FormLabel fontSize={'medium'}>What would you like help with today?</FormLabel>
            <Select>
                <option value="help-type">Help type</option>
            </Select>
        </FormControl>
    </Container>
</Box>)


export const SupportPage = () => {
    return (<Box position={'relative'}>
        <HeroWithCropedImage
            actions={<><Button
                size={{
                    base: 'lg',
                    md: 'xl',
                }}
            >
                Create a tickect
            </Button>
                <Button
                    variant="secondary"
                    size={{
                        base: 'lg',
                        md: 'xl',
                    }}
                >
                    Learn more
                </Button></>}
            options={formOptions}
            title={title}
            image={'https://res.cloudinary.com/druyvgiba/image/upload/v1705516222/Corobiz/AdobeStock_592218028_hwdxt6.png'}
        />

    </Box>
    )
}


