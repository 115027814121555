'use client'

import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Image,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react'
import subscriptionImage from '@assets/images/credit_repair/profile.svg'
import { Trans } from 'react-i18next'
import { CR_MANAGE_SUBSCRIPTION_BUTTON, CR_MANAGE_SUBSCRIPTION_INSTRUCTION, CR_MANAGE_SUBSCRIPTION_TITLE } from 'src/locales/types'

const transPath = "page.credit_repair.section.subscription"
export const CreditRepairSubscriptionManagement = () => {
    const handleCheckSubscription = () => {
        window.location.href = 'https://billing.stripe.com/p/login/7sIcNBgyn6aJ6ys7ss'
    }
    return (<Box bg={'bg.muted'}>
        
        <Center>
            <Stack minH={'100vh'} direction={{ base: 'column', lg: 'row' }} justify={'center'} >
                <Flex p={8} flex={1} align={'center'} justify={'center'}>
                    <Stack spacing={6} w={'full'} maxW={'lg'}>
                        <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
                        <Trans i18nKey={`${transPath}.${CR_MANAGE_SUBSCRIPTION_TITLE}`} />
                        </Heading>
                        <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                        <Trans i18nKey={`${transPath}.${CR_MANAGE_SUBSCRIPTION_INSTRUCTION}`} />
                        </Text>
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            <Button
                                onClick={handleCheckSubscription}
                                rounded={'full'}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}>
                             <Trans i18nKey={`${transPath}.${CR_MANAGE_SUBSCRIPTION_BUTTON}`} />
                            </Button>

                        </Stack>
                    </Stack>
                </Flex>
                <Flex flex={0.6}>
                    <Image
                        alt={'Manage Subscription'}
                        objectFit={'contain'}
                        src={
                            subscriptionImage
                        }
                    />
                </Flex>
            </Stack>
        </Center>
    </Box>
    )
}