import { NAVBAR_LINK_ABOUT_US, NAVBAR_LINK_CONTACT_US, NAVBAR_LINK_CREDIT_REPAIR, NAVBAR_LINK_HOME, NAVBAR_LINK_IMMIGRATION, NAVBAR_LINK_PASSPORT, NAVBAR_LINK_SERVICES, NAVBAR_LINK_SUPPORT, NAVBAR_LINK_TAXES, NAVBAR_LINK_TRAVEL } from "../types";


const esComponents = {
    navbar: {
        [NAVBAR_LINK_HOME]: "Inicio",
        [NAVBAR_LINK_IMMIGRATION]: "Inmigración",
        [NAVBAR_LINK_PASSPORT]: "Pasaporte",
        [NAVBAR_LINK_CONTACT_US]: "Contáctenos",
        [NAVBAR_LINK_ABOUT_US]: "Acerca de Nosotros",
        [NAVBAR_LINK_SUPPORT]: "Ayuda",

        [NAVBAR_LINK_SERVICES]: "Servicios",
        [NAVBAR_LINK_TAXES]: "Impuestos",
        [NAVBAR_LINK_TRAVEL]: "Viajes",
        [NAVBAR_LINK_CREDIT_REPAIR]: "Reparación de Crédito",

    }
}


export default esComponents;

