import { Button, Drawer, DrawerBody, DrawerContent, Stack, useDisclosure } from '@chakra-ui/react'

import { ToggleButton } from './ToggleButton'
import { useNavigate } from 'react-router-dom'
import { ServicesPopover } from './ServicesPopover'
import { navbarButtons, navbarRightLinks } from './NavbarNavigateOptions'
import { NavbarButtonLink } from './NavbarButtonLink'

export const MobileDrawer = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{
          base: 'inline-flex',
          lg: 'none',
        }}
      />
      <Drawer placement="top"  isOpen={isOpen} onClose={onClose} >
        <DrawerContent bg={'#0055AD'}>
          <DrawerBody mt="5em" p="4" fontSize={'xl'}>
            <Stack spacing="2">
            {navbarButtons.map((item) => {
                return (<NavbarButtonLink afterAction={onClose} key={item?.title} item={item} />)
              }
              )}
              {navbarRightLinks.map((item) => {
                return (<NavbarButtonLink afterAction={onClose} key={item?.title} item={item} />)
              }
              )}
               <ServicesPopover afterSelect={onClose} fontSize={'xl'}/>
            </Stack>
           
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
