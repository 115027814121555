export const scrollToSection = (href) => (e) => {
    console.log(href)
    try {
        const element = document.getElementById(href);
        e.preventDefault();
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });

    } catch (error) {
        console.log("Error in scroll to section", error);
    }


}